import { State, Action, StateContext, Selector } from '@ngxs/store';

import {Injectable} from '@angular/core';

import {Billing, billingDefault} from '../../shared/models/billing.model';
import {
  LoadCompanyBilling,
  SetCompanyBillingBusy,
  SetCompanyBillingFree,
  UpdateCompanyBilling
} from '../actions/company-billing.actions';
import {CompanyBillingService} from '../../shared/services/company-billing.service';
import {tap} from 'rxjs/operators';

export class CompanyBillingStateModel {
  companyBilling: Billing;
  loading: boolean;
}

@State<CompanyBillingStateModel>({
  name: 'companyBilling',
  defaults: {
    companyBilling: billingDefault,
    loading: false
  },
})

@Injectable()
export class CompanyBillingState {
  constructor(private companyBillingService: CompanyBillingService) {
  }

  @Selector()
  static getCompanyBilling(state: CompanyBillingStateModel) {
      return state.companyBilling;
  }

  @Action(LoadCompanyBilling)
  loadCompanyBilling({patchState }: StateContext<CompanyBillingStateModel>) {
    patchState({loading: true});
    return this.companyBillingService.getCompanyBilling().pipe(
      tap((res) => {
        patchState({loading: false, companyBilling: res});
      })
    );
  }

  @Action(UpdateCompanyBilling)
  updateCompanyBilling({patchState}: StateContext<CompanyBillingStateModel>, {payload}: UpdateCompanyBilling) {
    patchState({loading: true});
    return this.companyBillingService.editCompanySubscription(payload).pipe(
      tap((res) => {
        patchState({loading: false, companyBilling: res});
      })
    );
  }

  @Action(SetCompanyBillingFree)
  setCompanyBillingBusy({patchState}: StateContext<CompanyBillingStateModel>) {
    patchState({loading: false});
  }

  @Action(SetCompanyBillingBusy)
  setCompanyBillingFree({patchState}: StateContext<CompanyBillingStateModel>) {
    patchState({loading: true});
  }
}


