import { State, Action, StateContext, Selector } from '@ngxs/store';
import {Injectable} from '@angular/core';
import {patch, updateItem} from '@ngxs/store/operators';
import {FilterStringEntered, UpdateProjectItem, UpdateProjects} from '../actions/projects.actions';
import {ProjectItem} from '../../shared/models/project.model';

export class ProjectsStateModel {
  projects: ProjectItem[];
  filterText: string;
}

@State<ProjectsStateModel>({
  name: 'projects',
  defaults: {
    projects: [],
    filterText: ''
  }
})

@Injectable()
export class ProjectsState {

  @Selector()
  static getProjects(state: ProjectsStateModel) {
    return state.projects;
  }

  @Selector()
  static getFilteredProjects(state: ProjectsStateModel) {
    return state.projects.filter(item =>
      item.name.toLowerCase().includes(state.filterText.toLowerCase().trim())
    );
  }

  @Selector()
  static getFilterText(state: ProjectsStateModel) {
    return state.filterText;
  }

  @Action(FilterStringEntered)
  updateFilter({patchState}: StateContext<ProjectsStateModel>, {payload}: FilterStringEntered) {
   patchState({ filterText: payload });
  }

  @Action(UpdateProjects)
  updateProjects({getState, patchState }: StateContext<ProjectsStateModel>, { payload }: UpdateProjects) {
    patchState({
      projects: payload
    });
  }

  @Action(UpdateProjectItem)
  updateProjectItem({getState, setState }: StateContext<ProjectsStateModel>, { payload }: UpdateProjectItem) {
    setState(
      patch(
        {projects: updateItem(item => item.id === payload.id,  patch({ ...payload }) )}
      )
    );
  }

}


