import {Injectable} from '@angular/core';

// JSON cannot be easily imported in this project so this class was made
@Injectable({
    providedIn: 'root'
})
export default class LanguageColorData {
    public data = {
        '1c enterprise': '#814ccc',
        '4d': '#444444',
        'abap': '#e8274b',
        'actionscript': '#882b0f',
        'ada': '#02f88c',
        'agda': '#315665',
        'ags script': '#b9d9ff',
        'alloy': '#64c800',
        'alpine abuild': '#444444',
        'ampl': '#e6efbb',
        'angelscript': '#c7d7dc',
        'antlr': '#9dc3ff',
        'apex': '#444444',
        'api blueprint': '#2acca8',
        'apl': '#5a8164',
        'apollo guidance computer': '#444444',
        'applescript': '#101f1f',
        'arc': '#aa2afe',
        'asp': '#6a40fd',
        'aspectj': '#a957b0',
        'assembly': '#6e4c13',
        'asymptote': '#4a0c0c',
        'ats': '#1ac620',
        'augeas': '#444444',
        'autohotkey': '#6594b9',
        'autoit': '#1c3552',
        'awk': '#444444',
        'ballerina': '#ff5000',
        'batchfile': '#c1f12e',
        'befunge': '#444444',
        'bison': '#444444',
        'bitbake': '#444444',
        'blitzbasic': '#444444',
        'blitzmax': '#cd6400',
        'bluespec': '#444444',
        'boo': '#d4bec1',
        'brainfuck': '#2f2530',
        'brightscript': '#444444',
        'c': '#555555',
        'c#': '#178600',
        'c++': '#f34b7d',
        'c2hs haskell': '#444444',
        'cartocss': '#444444',
        'ceylon': '#dfa535',
        'chapel': '#8dc63f',
        'charity': '#444444',
        'chuck': '#444444',
        'cirru': '#ccccff',
        'clarion': '#db901e',
        'clean': '#3f85af',
        'click': '#e4e6f3',
        'clips': '#444444',
        'clojure': '#db5855',
        'cmake': '#444444',
        'cobol': '#444444',
        'codeql': '#444444',
        'coffeescript': '#244776',
        'coldfusion': '#ed2cd6',
        'coldfusion cfc': '#444444',
        'common lisp': '#3fb68b',
        'common workflow language': '#b5314c',
        'component pascal': '#b0ce4e',
        'cool': '#444444',
        'coq': '#444444',
        'crystal': '#000100',
        'csound': '#444444',
        'csound document': '#444444',
        'csound score': '#444444',
        'css': '#563d7c',
        'cuda': '#3a4e3a',
        'cweb': '#444444',
        'cycript': '#444444',
        'cython': '#444444',
        'd': '#ba595e',
        'dart': '#00b4ab',
        'dataweave': '#003a52',
        'dhall': '#dfafff',
        'digital command language': '#444444',
        'dm': '#447265',
        'dockerfile': '#384d54',
        'dogescript': '#cca760',
        'dtrace': '#444444',
        'dylan': '#6c616e',
        'e': '#ccce35',
        'ec': '#913960',
        'ecl': '#8a1267',
        'eclipse': '#444444',
        'eiffel': '#946d57',
        'elixir': '#6e4a7e',
        'elm': '#60b5cc',
        'emacs lisp': '#c065db',
        'emberscript': '#fff4f3',
        'eq': '#a78649',
        'erlang': '#b83998',
        'f#': '#b845fc',
        'f*': '#572e30',
        'factor': '#636746',
        'fancy': '#7b9db4',
        'fantom': '#14253c',
        'faust': '#c37240',
        'filebench wml': '#444444',
        'filterscript': '#444444',
        'fish': '#444444',
        'flux': '#88ccff',
        'forth': '#341708',
        'fortran': '#4d41b1',
        'freemarker': '#0050b2',
        'frege': '#00cafe',
        'g-code': '#d08cf2',
        'game maker language': '#71b417',
        'gaml': '#ffc766',
        'gams': '#444444',
        'gap': '#444444',
        'gcc machine description': '#444444',
        'gdb': '#444444',
        'gdscript': '#355570',
        'genie': '#fb855d',
        'genshi': '#444444',
        'gentoo ebuild': '#444444',
        'gentoo eclass': '#444444',
        'gherkin': '#5b2063',
        'glsl': '#444444',
        'glyph': '#c1ac7f',
        'gnuplot': '#f0a9f0',
        'go': '#00add8',
        'golo': '#88562a',
        'gosu': '#82937f',
        'grace': '#444444',
        'grammatical framework': '#79aa7a',
        'groovy': '#e69f56',
        'groovy server pages': '#444444',
        'hack': '#878787',
        'harbour': '#0e60e3',
        'haskell': '#5e5086',
        'haxe': '#df7900',
        'hcl': '#444444',
        'hiveql': '#dce200',
        'hlsl': '#444444',
        'holyc': '#ffefaf',
        'html': '#e34c26',
        'hy': '#7790b2',
        'hyphy': '#444444',
        'idl': '#a3522f',
        'idris': '#b30000',
        'igor pro': '#0000cc',
        'inform 7': '#444444',
        'inno setup': '#444444',
        'io': '#a9188d',
        'ioke': '#078193',
        'isabelle': '#fefe00',
        'isabelle root': '#444444',
        'j': '#9eedff',
        'jasmin': '#444444',
        'java': '#b07219',
        'java server pages': '#444444',
        'javascript': '#f1e05a',
        'javascript+erb': '#444444',
        'jflex': '#444444',
        'jison': '#444444',
        'jison lex': '#444444',
        'jolie': '#843179',
        'jsoniq': '#40d47e',
        'jsonnet': '#0064bd',
        'jsx': '#444444',
        'julia': '#a270ba',
        'jupyter notebook': '#da5b0b',
        'kotlin': '#f18e33',
        'krl': '#28430a',
        'labview': '#444444',
        'lasso': '#999999',
        'lean': '#444444',
        'lex': '#dbca00',
        'lfe': '#4c3023',
        'lilypond': '#444444',
        'limbo': '#444444',
        'literate agda': '#444444',
        'literate coffeescript': '#444444',
        'literate haskell': '#444444',
        'livescript': '#499886',
        'llvm': '#185619',
        'logos': '#444444',
        'logtalk': '#444444',
        'lolcode': '#cc9900',
        'lookml': '#652b81',
        'loomscript': '#444444',
        'lsl': '#3d9970',
        'lua': '#000080',
        'm': '#444444',
        'm4': '#444444',
        'm4sugar': '#444444',
        'makefile': '#427819',
        'mako': '#444444',
        'mask': '#f97732',
        'mathematica': '#444444',
        'matlab': '#e16737',
        'max': '#c4a79c',
        'maxscript': '#00a6a6',
        'mcfunction': '#e22837',
        'mercury': '#ff2b2b',
        'meson': '#007800',
        'metal': '#8f14e9',
        'minid': '#444444',
        'mirah': '#c7a938',
        'mirc script': '#926059',
        'mlir': '#5ec8db',
        'modelica': '#444444',
        'modula-2': '#444444',
        'modula-3': '#223388',
        'module management system': '#444444',
        'monkey': '#444444',
        'moocode': '#444444',
        'moonscript': '#444444',
        'motorola 68k assembly': '#444444',
        'mql4': '#62a8d6',
        'mql5': '#4a76b8',
        'mtml': '#b7e1f4',
        'muf': '#444444',
        'mupad': '#444444',
        'myghty': '#444444',
        'nasl': '#444444',
        'ncl': '#28431f',
        'nearley': '#990000',
        'nemerle': '#3d3c6e',
        'nesc': '#94b0c7',
        'netlinx': '#0aa0ff',
        'netlinx+erb': '#747faa',
        'netlogo': '#ff6375',
        'newlisp': '#87aed7',
        'nextflow': '#3ac486',
        'nim': '#37775b',
        'nit': '#009917',
        'nix': '#7e7eff',
        'nsis': '#444444',
        'nu': '#c9df40',
        'numpy': '#444444',
        'objective-c': '#438eff',
        'objective-c++': '#6866fb',
        'objective-j': '#ff0c5a',
        'objectscript': '#424893',
        'ocaml': '#3be133',
        'odin': '#60affe',
        'omgrofl': '#cabbff',
        'ooc': '#b0b77e',
        'opa': '#444444',
        'opal': '#f7ede0',
        'open policy agent': '#444444',
        'opencl': '#444444',
        'openedge abl': '#444444',
        'openqasm': '#aa70ff',
        'openrc runscript': '#444444',
        'openscad': '#444444',
        'ox': '#444444',
        'oxygene': '#cdd0e3',
        'oz': '#fab738',
        'p4': '#7055b5',
        'pan': '#cc0000',
        'papyrus': '#6600cc',
        'parrot': '#f3ca0a',
        'parrot assembly': '#444444',
        'parrot internal representation': '#444444',
        'pascal': '#e3f171',
        'pawn': '#dbb284',
        'pep8': '#c76f5b',
        'perl': '#0298c3',
        'php': '#4f5d95',
        'picolisp': '#444444',
        'piglatin': '#fcd7de',
        'pike': '#005390',
        'plpgsql': '#444444',
        'plsql': '#dad8d8',
        'pogoscript': '#d80074',
        'pony': '#444444',
        'postscript': '#da291c',
        'pov-ray sdl': '#444444',
        'powerbuilder': '#8f0f8d',
        'powershell': '#012456',
        'processing': '#0096d8',
        'prolog': '#74283c',
        'propeller spin': '#7fa2a7',
        'puppet': '#302b6d',
        'purebasic': '#5a6986',
        'purescript': '#1d222d',
        'python': '#3572a5',
        'python console': '#444444',
        'q': '#0040cd',
        'qmake': '#444444',
        'qml': '#44a51c',
        'quake': '#882233',
        'r': '#198ce7',
        'racket': '#3c5caa',
        'ragel': '#9d5200',
        'raku': '#0000fb',
        'raml': '#77d9fb',
        'rascal': '#fffaa0',
        'realbasic': '#444444',
        'reason': '#ff5847',
        'rebol': '#358a5b',
        'red': '#f50000',
        'redcode': '#444444',
        'renpy': '#ff7f7f',
        'renderscript': '#444444',
        'rexx': '#444444',
        'ring': '#2d54cb',
        'riot': '#a71e49',
        'robotframework': '#444444',
        'roff': '#ecdebe',
        'rouge': '#cc0088',
        'rpc': '#444444',
        'ruby': '#701516',
        'runoff': '#665a4e',
        'rust': '#dea584',
        'sage': '#444444',
        'saltstack': '#646464',
        'sas': '#b34936',
        'scala': '#c22d40',
        'scheme': '#1e4aec',
        'scilab': '#444444',
        'sed': '#64b970',
        'self': '#0579aa',
        'shaderlab': '#444444',
        'shell': '#89e051',
        'shellsession': '#444444',
        'shen': '#120f14',
        'slash': '#007eff',
        'slice': '#003fa2',
        'smali': '#444444',
        'smalltalk': '#596706',
        'smarty': '#444444',
        'smpl': '#c94949',
        'smt': '#444444',
        'solidity': '#aa6746',
        'sourcepawn': '#5c7611',
        'sqf': '#3f3f3f',
        'sqlpl': '#444444',
        'squirrel': '#800000',
        'srecode template': '#348a34',
        'stan': '#b2011d',
        'standard ml': '#dc566d',
        'starlark': '#76d275',
        'stata': '#444444',
        'supercollider': '#46390b',
        'swift': '#ffac45',
        'swig': '#444444',
        'systemverilog': '#dae1c2',
        'tcl': '#e4cc98',
        'tcsh': '#444444',
        'terra': '#00004c',
        'tex': '#3d6117',
        'thrift': '#444444',
        'ti program': '#a0aa87',
        'tla': '#444444',
        'tsql': '#444444',
        'tsx': '#444444',
        'turing': '#cf142b',
        'txl': '#444444',
        'typescript': '#2b7489',
        'unified parallel c': '#444444',
        'unix assembly': '#444444',
        'uno': '#444444',
        'unrealscript': '#a54c4d',
        'urweb': '#444444',
        'v': '#5d87bd',
        'vala': '#fbe5cd',
        'vba': '#867db1',
        'vbscript': '#15dcdc',
        'vcl': '#148aa8',
        'verilog': '#b2b7f8',
        'vhdl': '#adb2cb',
        'vim script': '#199f4b',
        'visual basic .net': '#945db7',
        'volt': '#1f1f1f',
        'vue': '#2c3e50',
        'wdl': '#42f1f4',
        'webassembly': '#04133b',
        'webidl': '#444444',
        'wisp': '#7582d1',
        'wollok': '#a23738',
        'x10': '#4b6bef',
        'xbase': '#403a40',
        'xc': '#99da07',
        'xojo': '#444444',
        'xproc': '#444444',
        'xquery': '#5232e7',
        'xs': '#444444',
        'xslt': '#eb8ceb',
        'xtend': '#444444',
        'yacc': '#4b6c4b',
        'yara': '#220000',
        'yasnippet': '#32ab90',
        'zap': '#0d665e',
        'zeek': '#444444',
        'zenscript': '#00bcd1',
        'zephir': '#118f9e',
        'zig': '#ec915c',
        'zil': '#dc75e5',
        'zimpl': '#444444'
    };
}
