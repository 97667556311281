export interface MemberDirectory {
  id?: number;
  name?: string;
  type?: string;
  host?: string;
  login?: string;
  base_dn?: string;
  ldap_user_filter?: string;
  ldap_group_filter?: string;
  use_for_authentication?: boolean;
  password?: string;
  domain?: string;
  azure_group_id?: string;
  auto_sync?: boolean;
  synced?: string;
  last_error?: string;
  is_syncing_now?: boolean;
  sync_now?: boolean;
  connection_error?: string;
  oauth_code?: string;
  is_oauth_awaiting_completion?: boolean;
  oauth_credentials?: string;
  scim_endpoint?: string;
  scim_token?: string;
  saml_idp_metadata_url?: string;
}

export const memberDirectoryDefault = {
  id: null,
  name: '',
  type: 'openLDAP',
  host: 'ldap://',
  login: '',
  base_dn: '',
  ldap_user_filter: '',
  ldap_group_filter: '',
  use_for_authentication: false,
  password: '',
  domain: '',
  azure_group_id: '',
  oauth_code: '',
  oauth_not_completed: false,
  scim_endpoint: '',
  scim_token: ''
};
