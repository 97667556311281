import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
    UpdateMemberItem,
    UpdateMembers,
    UpdateMembersTotal,
    SetSelectedMember,
    AddPolicyToSelectedMember,
    RemovePolicyFromSelectedMember,
    UpdateCertainPolicyInSelectedMember,
    AddGroupToSelectedMember, RemoveGroupFromSelectedMember
} from '../actions/members.actions';
import {Injectable} from '@angular/core';
import {MemberItem, memberItemDefault} from '../../shared/models/member.model';
import {append, insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {MemberGroup} from '../../shared/models/group.model';

export class MembersStateModel {
    members: MemberItem[];
    selectedMember: MemberItem;
    membersTotal: number;
}

@State<MembersStateModel>({
    name: 'members',
    defaults: {
        members: null,
        selectedMember: memberItemDefault,
        membersTotal: null,
    }
})

@Injectable()
export class MembersState {

    @Selector()
    static getMembers(state: MembersStateModel) {
        return state.members;
    }

    @Selector()
    static getSelectedMember(state: MembersStateModel) {
        return state.selectedMember;
    }

    @Selector()
    static getMembersTotal(state: MembersStateModel) {
        return state.membersTotal;
    }

    @Action(UpdateMembers)
    updateMembers({getState, patchState }: StateContext<MembersStateModel>, { payload }: UpdateMembers) {
        patchState({
            members: payload
        });
    }

    @Action(UpdateMemberItem)
    updateMemberItem({getState, setState }: StateContext<MembersStateModel>, { payload }: UpdateMemberItem) {
        setState(
            patch(
            {members: updateItem(item => item.id === payload.id,  patch({ ...payload }) )}
            )
        );
    }

    @Action(UpdateMembersTotal)
    updateMembersTotal({getState, patchState }: StateContext<MembersStateModel>, { payload }: UpdateMembersTotal) {
        patchState({
            membersTotal: payload
        });
    }

    @Action(SetSelectedMember)
    setSelectedMember({getState, setState}: StateContext<MembersStateModel>, {payload}: SetSelectedMember) {
        if (payload == null) {
            payload = memberItemDefault;
        }
        setState(
            patch({
              selectedMember: patch(
                {...payload }
              )
            })
        );
    }

    @Action(UpdateCertainPolicyInSelectedMember)
    updateCertainPolicyInSelectedMember({getState, setState}: StateContext<MembersStateModel>, {payload}: UpdateCertainPolicyInSelectedMember) {
        setState(
            patch({
              selectedMember: patch(
                {access_policies: updateItem(item => item.id === payload.id,  patch({...payload}) )}
              )
            })
        );
    }

    @Action(AddPolicyToSelectedMember)
    addPolicyToSelectedMember({getState, setState}: StateContext<MembersStateModel>, {payload}: AddPolicyToSelectedMember) {
        setState(
            patch({
              selectedMember: patch(
                {access_policies: insertItem(payload, 0) }
              )
            })
        );
    }

    @Action(RemovePolicyFromSelectedMember)
    removePolicyFromSelectedMember({getState, setState}: StateContext<MembersStateModel>, {payload}: RemovePolicyFromSelectedMember) {
        setState(
            patch({
              selectedMember: patch(
                {access_policies: removeItem( payload) }
              )
            })
        );
    }

    @Action(AddGroupToSelectedMember)
    addGroupToSelectedMember({getState, setState}: StateContext<MembersStateModel>, {payload}: AddGroupToSelectedMember) {
        setState(
            patch({
              selectedMember: patch({
                groups_representation: insertItem(payload),
                groups: insertItem(payload.id),
                group_access_policies: append(payload.access_policies)
              })
            })
        );
    }

    @Action(RemoveGroupFromSelectedMember)
    removeGroupFromSelectedMember({getState, setState}: StateContext<MembersStateModel>, {payload}: RemoveGroupFromSelectedMember) {
        const initialGroupPolicies = getState().selectedMember.group_access_policies;
        const groupPoliciesToDelete = payload.access_policies;
        const groupPoliciesResult = initialGroupPolicies.filter(
          fromInitial => !groupPoliciesToDelete.some(toDelete => fromInitial.id === toDelete.id)
        );
        setState(
            patch({
              selectedMember: patch(
                {
                  groups_representation: removeItem( (item: MemberGroup) => item.id === payload.id),
                  groups: removeItem( item => item === payload.id),
                  group_access_policies: groupPoliciesResult
                }
              )
            })
        );
    }
}


