import { State, Action, StateContext, Selector } from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SubscriptionInfo, subscriptionInfoDefault} from '../../shared/models/subscription-info.model';
import {UpdateSubscriptionInfo} from '../actions/subscription-info.actions';

export class SubscriptionInfoStateModel {
  subscriptionInfo: SubscriptionInfo;
}

@State<SubscriptionInfoStateModel>({
  name: 'subscriptionInfo',
  defaults: {
    subscriptionInfo: subscriptionInfoDefault,
  }
})

@Injectable()
export class SubscriptionInfoState {

  @Selector()
  static getSubscriptionInfo(state: SubscriptionInfoStateModel) {
    return state.subscriptionInfo;
  }

  @Action(UpdateSubscriptionInfo)
  add({getState, patchState }: StateContext<SubscriptionInfoStateModel>, { payload }: UpdateSubscriptionInfo) {
    const state = getState();
    patchState({
      subscriptionInfo: {...state.subscriptionInfo, ...payload}
    });
  }
}
