import { Component, OnInit, AfterViewInit, ViewChild, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd
} from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../services/theme.service';
import { LayoutService } from '../../../services/layout.service';
import { filter } from 'rxjs/operators';
import {StorageService} from '../../../services/storage.service';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {UpdateCurrentUser} from '../../../../store/actions/current-user.actions';
import {UserDetailsService} from '../../../services/user-details.service';
import {UserService} from '../../../services/user.service';
import {Company} from '../../../models/company.model';
import {SubscriptionInfoService} from '../../../services/subscription-info.service';
import {CurrentUser} from '../../../models/current-user.model';
import * as moment from 'moment';
import {UpdateSubscriptionInfo} from '../../../../store/actions/subscription-info.actions';
import {SubscriptionInfo} from '../../../models/subscription-info.model';
import {LoadCompanyBilling} from '../../../../store/actions/company-billing.actions';
import {UpdatePublicApiToken} from '../../../../store/actions/public-api-token.actions';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {
  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;

  public  scrollConfig = {}
  public layoutConf: any = {};
  public adminContainerClasses: any = {};

  constructor(
    private router: Router,
    public themeService: ThemeService,
    private layout: LayoutService,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService,
    private _actions: Actions,
    private store: Store,
    public userDetailsService: UserDetailsService,
    private userService: UserService,
    private subscriptionInfoService: SubscriptionInfoService,
  ) {
    // Close sidenav after route change in mobile
    this.routerEventSub = router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((routeChange: NavigationEnd) => {
      this.layout.adjustLayout({ route: routeChange.url });
      this.scrollToTop();
    });
  }
  ngOnInit() {
    this.userDetailsService.get_current_user().subscribe((user: CurrentUser) => {
        this.store.dispatch(new UpdateCurrentUser(user));
        if (user.is_superuser) {
              this.store.dispatch(new LoadCompanyBilling());
        }
      });
    this.userDetailsService.getAPIToken().subscribe(res => {
      this.store.dispatch(new UpdatePublicApiToken(res.token));
    });
    // this.layoutConf = this.layout.layoutConf;
    this.layoutConfSub = this.layout.layoutConf$.subscribe((layoutConf) => {
        this.layoutConf = layoutConf;
        // console.log(this.layoutConf);

        this.adminContainerClasses = this.updateAdminContainerClasses(this.layoutConf);
        this.cdr.markForCheck();
    });

    this.subscriptionInfoService.getSubscriptionInfo().subscribe((subscriptionInfo: SubscriptionInfo) => {
      this.store.dispatch(new UpdateSubscriptionInfo(subscriptionInfo));
    });

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if(event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if(event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });
    moment.updateLocale('ru', this.storageService.ruLocaleSpec);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout.adjustLayout(event);
  }

  ngAfterViewInit() {

  }

  scrollToTop() {
    if(document) {
      setTimeout(() => {
        let element;
        if(this.layoutConf.topbarFixed) {
          element = <HTMLElement>document.querySelector('#rightside-content-hold');
        } else {
          element = <HTMLElement>document.querySelector('#main-content-wrap');
        }
        element.scrollTop = 0;
      })
    }
  }
  ngOnDestroy() {
    if(this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if(this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if(this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  sidebarMouseenter(e) {
    // console.log(this.layoutConf);
    if(this.layoutConf.sidebarStyle === 'compact') {
        this.layout.publishLayoutChange({sidebarStyle: 'full', sidebarCompactToggle: false}, {transitionClass: true});
    }
  }

  sidebarMouseleave(e) {
    // console.log(this.layoutConf);
    if (
        this.layoutConf.sidebarStyle === 'full' &&
        this.layout.sidebarCompactEnabled
    ) {
        this.layout.publishLayoutChange({sidebarStyle: 'compact', sidebarCompactToggle: true}, {transitionClass: true});
    }
  }

  updateAdminContainerClasses(layoutConf) {
    return {
      'navigation-top': layoutConf.navigationPos === 'top',
      'sidebar-full': layoutConf.sidebarStyle === 'full',
      'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
      'compact-toggle-active': layoutConf.sidebarCompactToggle,
      'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
      'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
      'sidebar-closed': layoutConf.sidebarStyle === 'closed',
      'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side'
    }
  }

}
