import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';
import {ThemeService} from '../../services/theme.service';
import {Observable, Subscription} from 'rxjs';
import {ILayoutConf, LayoutService} from 'app/shared/services/layout.service';
import {UserDetailsService} from '../../services/user-details.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {CurrentUser} from '../../models/current-user.model';
import {Store} from '@ngxs/store';
import {MemberItem} from '../../models/member.model';
import {buildInfo} from '../../../../build';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'app-sidebar-side',
    templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
    public menuItems: any[];
    public bottomMenuItems: any[];
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    private bottomMenuItemsSub: Subscription;
    public layoutConf: ILayoutConf;
    public avatar: string;
    private alive = true;
    currentUser$: Observable<CurrentUser>;
    public buildTimestamp = moment(buildInfo.timestamp);
    public dateTimeDisplayFormat = StorageService.dateTimeDisplayFormat;

    constructor(
        private navService: NavigationService,
        public themeService: ThemeService,
        public layout: LayoutService,
        public userDetailsService: UserDetailsService,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private store: Store,
        public translateService: TranslateService,
        private storageService: StorageService
    ) {
        this.currentUser$ = this.store.select(state => state.currentUser.currentUser);
    }

    ngOnInit() {
        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
        this.bottomMenuItems = this.navService.bottomMenu;
        this.currentUser$.takeWhile(() => this.alive).subscribe(
        user => {
                this.navService.publishNavigationChange(this.getMenuType(user));
                this.loadMenu();
                this.loadBottomMenu();
            }
        );
        this.layoutConf = this.layout.layoutConf;
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.alive = false;
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }if (this.bottomMenuItemsSub) {
            this.bottomMenuItemsSub.unsubscribe();
        }
    }

    getMenuType(user: MemberItem | CurrentUser): string {
        // Admin
        if (this.userService.hasAdminPolicy(user)) {
           return 'admin-menu';
        }

        // Observers
        const hasGroupObserverPolicy = this.userService.hasGroupObserverPolicy(user);
        const hasProjectObserverPolicy = this.userService.hasProjectObserverPolicy(user);

        if (hasGroupObserverPolicy && hasProjectObserverPolicy) {
            return 'group-and-project-observer-menu';
        }
        if (hasGroupObserverPolicy) {
            return 'group-observer-menu';
        }
        if (hasProjectObserverPolicy) {
            return 'project-observer-menu';
        }

        // Default
        return 'member-menu';
    }

    loadMenu() {
        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
            this.menuItems = menuItem;

            // Checks item list has any icon type.
            this.hasIconTypeMenuItem = !!this.menuItems.filter(
                item => item.type === 'icon'
            ).length;
        });
    }

    loadBottomMenu() {
        this.bottomMenuItemsSub = this.navService.bottomMenuItems$.subscribe(menuItem => {
            this.bottomMenuItems = menuItem;

            // Checks item list has any icon type.
            this.hasIconTypeMenuItem = !!this.bottomMenuItems.filter(
                item => item.type === 'icon'
            ).length;
        });
    }

    toggleCollapse() {
        this.layout.sidebarCompactEnabled = !this.layout.sidebarCompactEnabled;
    }

    logout() {
        this.userService.logout();
    }
}
