import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import {CancellableDialogWindowParams} from '../../services/popup-windows.service';

@Component({
  selector: 'app-dialog-window',
  templateUrl: './dialog-window.component.html',
  styleUrls: ['./dialog-window.component.scss']
})
export class DialogWindowComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: CancellableDialogWindowParams) {
  }

  ngOnInit() {
  }

}
