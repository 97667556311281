import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {CurrentUser} from '../../../models/current-user.model';
import {UpdateCurrentUser} from '../../../../store/actions/current-user.actions';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {

  currentUser$: Observable<CurrentUser>;

  constructor(
    public translate: TranslateService,
    private storageService: StorageService,
    private store: Store
  ) {
    this.currentUser$ = this.store.select(state => state.currentUser.currentUser);
  }

  ngOnInit() {
  }

}
