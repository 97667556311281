<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">

            <!-- App Logo -->
            <div class="branding" fxLayoutAlign="center center">
                <a href="https://teamtrackerhq.com/" class="sidebar-logo" fxLayout="row" fxLayoutAlign="center center">
                    <img src="assets/images/logo.png" alt="" class="app-logo mb-05">
                    <span class="app-logo-text" style="height: 64px; white-space: nowrap;">Team Tracker</span>
                </a>

                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div
                        class="sidebar-compact-switch"
                        [ngClass]="{active: layout.sidebarCompactEnabled}"
                        (click)="toggleCollapse()"
                        *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>
                </div>

            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                    <img [src]="(currentUser$ | async).avatar" class="mat-elevation-z1" alt="">
                </div>
                <span class="app-user-name mb-05">
                <mat-icon class="icon-xs text-muted">lock</mat-icon>
                {{(currentUser$ | async).first_name}} {{(currentUser$ | async).last_name}}
                </span>
                <!-- Small buttons -->
                <div class="app-user-controls">
                    <button
                            class="text-muted"
                            mat-icon-button
                            mat-xs-button
                            [matMenuTriggerFor]="appUserMenu">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <button
                            class="text-muted"
                            mat-icon-button
                            mat-xs-button
                            matTooltip="{{ 'Sign out' | translate }}"
                            (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                    <mat-menu #appUserMenu="matMenu">
                        <button mat-menu-item routerLink="/profile/settings" type="button">
                            <mat-icon>settings</mat-icon>
                            <span>{{ 'Account Settings' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="logout()" type="button">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>{{ 'Sign out' | translate }}</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
        <div fxFlex></div>
        <div class="sidebar-hold">
            <app-sidenav [items]="bottomMenuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
        <div *ngIf="layoutConf.sidebarStyle === 'full'" class="mb-1" fxLayoutAlign="center end" style="color: gray;">
            {{ 'Current build' | translate }}:
            {{ buildTimestamp.isValid() ? buildTimestamp.locale(translateService.currentLang).format(dateTimeDisplayFormat) : 'Unavailable' | translate }}
        </div>
    </div>
</div>
