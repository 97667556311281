import {Injectable} from '@angular/core';
import {AccessPolicyMatSelectOption} from '../models/group/access-policy.model';

const USER_ID = 'USER_ID';
const LANGUAGE = 'language';

@Injectable({
    providedIn: 'root'
})

export class StorageService {
    public static readonly datesStorageFormat = 'YYYY-MM-DD';
    public static readonly datesDisplayFormat = 'ddd MMM DD, YYYY';
    public static readonly dateTimeDisplayFormat = 'YYYY-MM-DD HH:mm';
    public static readonly lsReadErrMsg = 'Error occurred during reading from local storage';
    public static readonly lsWriteErrMsg = 'Error occurred during writing to local storage';

    private _userID: number;

    get userID(): number {
        if (!this._userID) {
            try {
                this._userID = parseInt(localStorage.getItem(USER_ID), 10);
            } catch (e) {
                console.error(StorageService.lsReadErrMsg);
            }
        }
        return this._userID;
    }

    set userID(value: number) {
        this._userID = value;
        try {
            localStorage.setItem(USER_ID, value.toString());
        } catch (e) {
            console.error(StorageService.lsWriteErrMsg);
        }
    }

    private _currLang: string;

    get currLang(): string {
        if (!this._currLang) {
            try {
                this._currLang = localStorage.getItem(LANGUAGE);
            } catch (e) {
                console.error(StorageService.lsReadErrMsg);
            }
        }
        return this._currLang;
    }

    set currLang(value: string) {
        this._currLang = value;
        try {
            localStorage.setItem(LANGUAGE, value);
        } catch (e) {
            console.error(StorageService.lsWriteErrMsg);
        }
    }


    public readonly WEEK_START_DAYS = [{
            name: 'Monday',
            code: 0
        }, {
            name: 'Sunday',
            code: 1
        }];
    public readonly REPORT_DAYS = [{
            name: 'same day',
            code: 0
        }, {
            name: 'next day',
            code: 1
        }];
    public readonly LANGUAGES = [{
            name: 'English',
            code: 'en',
            flag: 'flag-icon-us'
        }, {
            name: 'Русский',
            code: 'ru',
            flag: 'flag-icon-ru'
        }];
    public readonly ruLocaleSpec = {
      monthsShort : {
        format: ['Янв', 'Февр', 'Март', 'Апр', 'Мая', 'Июня', 'Июля', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        standalone: ['Янв', 'Февр', 'Март', 'Апр', 'Мая', 'Июня', 'Июля', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
      },
      weekdaysShort : ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    };
    public ownerPolicy: AccessPolicyMatSelectOption = {
      name: 'Tenant owner',
      code: 'owner',
      description: 'Tenant owner is a unique access policy assigned to the tenant creator',
      disabled: true
    };
    public adminPolicy: AccessPolicyMatSelectOption = {
      name: 'Administrator',
      code: 'admin',
      description: 'Administrators can perform CRUD operations on users, groups and directories, ' +
        'track all dashboards and manage access policies'
    };
    public groupObserverPolicy: AccessPolicyMatSelectOption = {
      name: 'Group observer',
      code: 'group_observer',
      description: 'Group observers can only track dashboard activity of members of a selected group'
    };
    public projectObserverPolicy: AccessPolicyMatSelectOption = {
      name: 'Project observer',
      code: 'project_observer',
      description: 'Project observers can only track dashboard activity of members of a selected project'
    };
    public readonly REPORT_HOURS = Array.from(Array(24).keys());
    public readonly WORKING_DAY_END_HOURS = Array.from(Array(13).keys());

    constructor() {
    }

    public deleteCurrentUserId(): void {
        this._userID = null;
        try {
            localStorage.removeItem(USER_ID);
        } catch (e) {
            console.error('Error occurred during removing item from local storage');
        }
    }

    public getLangCodes(): string[] {
      return this.LANGUAGES.reduce(function(acc, val){
        return [...acc, val.code];
      }, []);
    }
}
