import { State, Action, StateContext, Selector } from '@ngxs/store';
import {CurrentUser, currentUserDefault} from '../../shared/models/current-user.model';
import {UpdateCurrentUser} from '../actions/current-user.actions';
import {Injectable} from '@angular/core';

export class CurrentUserStateModel {
    currentUser: CurrentUser;
}

@State<CurrentUserStateModel>({
    name: 'currentUser',
    defaults: {
        currentUser: currentUserDefault,
    },
})

@Injectable()
export class CurrentUserState {
    constructor() {
    }

    @Selector()
    static getCurrentUser(state: CurrentUserStateModel) {
        return state.currentUser;
    }

    @Action(UpdateCurrentUser)
    updateCurrentUser({getState, patchState }: StateContext<CurrentUserStateModel>, { payload }: UpdateCurrentUser) {
        const state = getState();
        patchState({
            currentUser: {...state.currentUser, ...payload}
        });
    }
}


