import {AccessPolicy} from './group/access-policy.model';
import {MemberGroup} from './group.model';

export interface MemberListItem {
  id?: number;
  first_name?: string;
  last_name?: string;
  avatar?: string;
  username?: string;
  api_key?: string;
  member_directory_name?: string;
  last_beat?: string;
  plugin_version?: string;
  last_session_duration?: number;
  last_session_ip?: string;
}

export interface MemberItem extends MemberListItem {
  email?: string;
  groups?: number[];
  groups_representation?: MemberGroup[];
  access_policies?: AccessPolicy[];
  group_access_policies?: AccessPolicy[];
}

export const memberItemDefault: MemberItem = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  avatar: null,
  access_policies: [],
  group_access_policies: [],
  groups: [],
  groups_representation: [],
  last_session_duration: null,
  last_session_ip: ''
};


export interface PaginatedMemberList {
  count: number;
  next: string;
  previous: string;
  results: MemberItem[];
}

export const paginatedMemberListDefault: PaginatedMemberList = {
  count: null,
  next: '',
  previous: '',
  results: []
};
