import { ErrorHandler, Injectable, Injector, ChangeDetectorRef } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  // https://github.com/angular/angular/issues/17010  upd: 2021-02-02
  errorSubject = new Subject();

  constructor(protected injector: Injector) {
    super();

    this.errorSubject.pipe(debounceTime(250), distinctUntilChanged())
    .subscribe((error: Error | any) => {
      this.captureWithSentry(error);

      const debugCtx = error['ngDebugContext'];
      const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef);
      if (changeDetectorRef) {
        changeDetectorRef.attach();
      }
    });
  }

  handleError(error: any) {
    const debugCtx = error['ngDebugContext'];
    const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef);
    if (changeDetectorRef) {
      changeDetectorRef.detach();
    }
    this.errorSubject.next(error);
  }

  private captureWithSentry(error) {
    const extractedError = this.extractError(error);

    Sentry.captureException(extractedError);

    if (!environment.production) {
      console.error(extractedError);
    }
  }

  private extractError(error) {
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    return 'Handled unknown error';
  }
}
