export interface Price {
  id?: string;
  unit_amount?: number;
  description?: string;
  period?: string;
}

export const priceDefault: Price = {
  id: '',
  unit_amount: null,
  description: '',
  period: ''
};

export interface Plan {
  id?: string;
  name?: string;
  description?: string;
  monthly_price?: Price;
  yearly_price?: Price;
}

export const planDefault: Plan = {
  id: '',
  name: '',
  description: '',
  monthly_price: priceDefault,
  yearly_price: priceDefault,
};
