<div style="padding: 5px;">
  <h1 mat-dialog-title style="text-align: center">
      {{ data.title | translate }}
  </h1>
  <div mat-dialog-content class="mb-1" style="white-space: pre-wrap; text-align: center">
      {{ data.message | translate }}
  </div>
  <div mat-dialog-actions align="center">
    <button type="button" color="primary" mat-raised-button [mat-dialog-close]="true">
      {{ data.okButtonText | translate }}
    </button>
    <span *ngIf="data.cancelButtonText" fxFlex ></span>
    <button *ngIf="data.cancelButtonText" type="button" color="accent" mat-raised-button [mat-dialog-close]="false">
      {{ data.cancelButtonText | translate }}
    </button>
  </div>
</div>
