export interface SubscriptionInfo {
  max_users?: number;
  company_has_subscription?: boolean;
  show_user_limit_message?: boolean;
}

export const subscriptionInfoDefault: SubscriptionInfo = {
  max_users: null,
  company_has_subscription: false,
  show_user_limit_message: false,
};
