import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {StorageService} from './storage.service';
import {UrlService} from './url.service';
import {Router} from '@angular/router';
import {MemberItem} from '../models/member.model';
import {AccessPolicy} from '../models/group/access-policy.model';
import {CurrentUser} from '../models/current-user.model';

@Injectable({
    providedIn: 'root'
})

// All Http request here are not intercepted
export class UserService {
    private httpWithoutInterceptor: HttpClient;
    private http_options = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    public user_id?: number;

    private ownerPolicy = this.storageService.ownerPolicy;
    private adminPolicy = this.storageService.adminPolicy;
    private groupObserverPolicy = this.storageService.groupObserverPolicy;
    private projectObserverPolicy = this.storageService.projectObserverPolicy;

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private urlService: UrlService,
        private httpBackend: HttpBackend
    ) {
      this.httpWithoutInterceptor = new HttpClient(this.httpBackend);
    }

    public signup(email: string, password: string, rememberMe: boolean, timeZone: string, language: string) {
        return this.http.post(
            '/api/signup',
            JSON.stringify({
                'email': email,
                'password': password,
                'remember_me': rememberMe,
                'timezone': timeZone,
                'language': language
            }),
            this.http_options
        ).pipe(
            tap(res => this.storageService.userID = res['id'])
        );
    }

    public login(email: string, password: string, rememberMe: boolean) {
        return this.http.post(
            '/api/token/cookie',
            JSON.stringify({'email': email, 'password': password, 'remember_me': rememberMe}),
            this.http_options
        ).pipe(
            tap(res => this.storageService.userID = res['id'])
        );
    }

    public logout() {
        this.http.post('/api/token/cookie/logout', '', this.http_options).subscribe(
            res => {
                this.storageService.deleteCurrentUserId();
                this.urlService.navigateToUserSignin();
            }
        );
        return;
    }

    // Takes email and sends there a link to make a new password
    public restorePassword(email: string) {
      return this.httpWithoutInterceptor.post(
        '/api/restore-password', JSON.stringify({email: email}), this.http_options
      );
    }

    // Creates new password, needs token from restore password link
    public newPassword(email: string, password: string, token: string) {
        return this.http.post(
            '/api/new-password',
            JSON.stringify({'password': password, 'token': token, 'email': email}),
            this.http_options
        );
    }

    private isAdminPolicy(ap: AccessPolicy) {
        return [this.ownerPolicy.code, this.adminPolicy.code].includes(ap.type);
    }

    private isGroupObserverPolicy(ap: AccessPolicy) {
        return (ap.type === this.groupObserverPolicy.code) && ap.group;
    }

    private isProjectObserverPolicy(ap: AccessPolicy) {
        return (ap.type === this.projectObserverPolicy.code) && ap.project;
    }

    public hasAdminPolicy(member: MemberItem | CurrentUser) {
        return (member.access_policies.some(this.isAdminPolicy.bind(this)) ||
            member.group_access_policies.some(this.isAdminPolicy.bind(this)));
    }

    public hasGroupObserverPolicy(member: MemberItem | CurrentUser) {
        return (member.access_policies.some(this.isGroupObserverPolicy.bind(this)) ||
            member.group_access_policies.some(this.isGroupObserverPolicy.bind(this)));
    }

    public hasProjectObserverPolicy(member: MemberItem | CurrentUser) {
        return (member.access_policies.some(this.isProjectObserverPolicy.bind(this)) ||
            member.group_access_policies.some(this.isProjectObserverPolicy.bind(this)));
    }
}
