import { State, Action, StateContext, Selector } from '@ngxs/store';
import {Injectable} from '@angular/core';
import {UpdatePublicApiToken} from '../actions/public-api-token.actions';

export class PublicApiTokenStateModel {
  publicApiToken: string;
}

@State<PublicApiTokenStateModel>({
  name: 'publicApiToken',
  defaults: {
    publicApiToken: '',
  }
})

@Injectable()
export class PublicApiTokenState {

  @Selector()
  static getPublicApiToken(state: PublicApiTokenStateModel) {
    return state.publicApiToken;
  }

  @Action(UpdatePublicApiToken)
  add({getState, patchState }: StateContext<PublicApiTokenStateModel>, { payload }: UpdatePublicApiToken) {
    patchState({
      publicApiToken: payload
    });
  }
}
