import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend, HttpHeaders} from '@angular/common/http';
import {Router, RouterStateSnapshot} from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {catchError, tap} from 'rxjs/operators';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {Domain} from '../models/company.model';

const PROTOCOL = 'http://'; // for correct redirects during development on localhost
const BASE_DOMAIN = window.location.hostname.substring(window.location.hostname.indexOf('.'));
const PUBLIC_TENANT_NAME = 'app';
const DIRECTORY_PATHNAME = 'directory/list/';
const COMPANY_SIGNUP_PATHNAME = '/sessions/signup';
const USER_SIGNIN_PATHNAME = '/sessions/user/signin';
const USER_SIGNUP_PATHNAME = '/sessions/user/signup';
const DEFAULT_DASHBOARD_PATH = '/dashboard/default';
const WELCOME_PAGE_PATH = '/dashboard/welcome';
const PROFILE_SETTINGS_PATH = '/profile/settings';
const FORBIDDEN_PATH = 'sessions/403';

@Injectable({
    providedIn: 'root'
})

// All Http request here are not intercepted
export class UrlService {

    private http_options = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    public domainHasAnAdmin: Subject<boolean> = new BehaviorSubject(true);

    private static addProtocolAndPortToDomain (domain) {
        let url = PROTOCOL + domain;
        if (window.location.port) {
            url += `:${window.location.port}`;
        }
        return url;
    }

    constructor(private http: HttpClient, private router: Router) {
    }

    public getBaseDomain() {
        return BASE_DOMAIN;
    }

    public isPublicTenant() {
      return window.location.host.split('.')[0] === PUBLIC_TENANT_NAME;
    }

    public signup(name: string) {
        return this.http.post('/api/company/', JSON.stringify({'name': name}), this.http_options)
            .pipe(catchError(e => {
                return throwError(e);
            }));
    }

    public getCurrentDomain() {
      return this.http.get('/api/domain', this.http_options)
        .pipe(
          tap((domain: Domain) => this.domainHasAnAdmin.next(domain.has_admin)),
          catchError(e =>  throwError(e))
        );
    }

    public getDomainByName(domain: string) {
      const param = `/?domain=${domain}`;
      return this.http.get('/api/domain' + param, this.http_options)
        .pipe(catchError(e => {
          return throwError(e);
        }));
    }

    public redirectToCompanySignup() {
        let url = PROTOCOL + PUBLIC_TENANT_NAME + this.getBaseDomain();
        if (window.location.port) {
            url += `:${window.location.port}`;
        }
        url += COMPANY_SIGNUP_PATHNAME;
        window.location.href = url;
    }

    public redirectToUserSignin(domainName: string, lang?: string) {
        let url = UrlService.addProtocolAndPortToDomain(domainName);
        url += USER_SIGNIN_PATHNAME;
        if (lang) {
            url += `?lang=${lang}`;
        }
        window.location.href = url;
    }

    public navigateToUserSignin() {
      this.router.navigateByUrl(USER_SIGNIN_PATHNAME);
    }

    public redirectToUserSignup(domainName: string, lang?: string) {
        let url = UrlService.addProtocolAndPortToDomain(domainName);
        url += USER_SIGNUP_PATHNAME;
        if (lang) {
            url += `?lang=${lang}`;
        }
        window.location.href = url;
    }

    public navigateToDefaultDashboard() {
      this.router.navigate([DEFAULT_DASHBOARD_PATH]);
    }

    public navigateToCompanySignup() {
      this.router.navigate([COMPANY_SIGNUP_PATHNAME]);
    }

    public navigateToWelcomeInstructions() {
      this.router.navigateByUrl(WELCOME_PAGE_PATH);
    }

    public navigateToProfileSettings() {
      this.router.navigateByUrl(PROFILE_SETTINGS_PATH);
    }

    public navigateToMain() {
      this.router.navigateByUrl('');
    }

    public navigateToForbidden() {
      this.router.navigateByUrl(FORBIDDEN_PATH);
    }

    public getDirectorySubPath() {
        return DIRECTORY_PATHNAME;
    }
}
