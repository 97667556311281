import {ErrorHandler, NgModule, Injectable } from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig} from '@angular/material/core';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {InMemoryWebApiModule} from 'angular-in-memory-web-api';
import {InMemoryDataService} from './shared/inmemory-db/inmemory-db.service';
import { environment } from './../environments/environment';

import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';

import {HttpClient, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ErrorHandlerService} from './shared/services/error-handler.service';
import { NgxsModule } from '@ngxs/store';
import {CurrentUserState} from './store/state/current-user.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import {MembersState} from './store/state/members.state';
import {DirectoriesState} from './store/state/directories.state';
import {GroupsState} from './store/state/groups.state';
import {ProjectsState} from './store/state/projects.state';
import {AdminLayoutGuard} from './shared/guards/admin-layout.guard';
import {DashboardGuard} from './shared/guards/dashboard.guard';
import {SubscriptionInfoState} from './store/state/subscription-info.state';
import {CompanyBillingState} from './store/state/company-billing.state';
import {PublicApiTokenState} from './store/state/public-api-token.state';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        PerfectScrollbarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        InMemoryWebApiModule.forRoot(InMemoryDataService, {passThruUnknownUrl: true}),
        RouterModule.forRoot(rootRouterConfig, {useHash: false}),
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-CSRF-TOKEN'
        }),
        NgxsModule.forRoot([
          CurrentUserState, MembersState, DirectoriesState, GroupsState, ProjectsState, SubscriptionInfoState,
          CompanyBillingState, PublicApiTokenState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({disabled: environment.production})
        ],
    declarations: [AppComponent],
    providers: [
        {provide: ErrorHandler, useClass: ErrorHandlerService},
        {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        AdminLayoutGuard,
        DashboardGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
