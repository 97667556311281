import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {LayoutService} from '../../services/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {UserDetailsService} from '../../services/user-details.service';
import 'rxjs/add/operator/takeWhile';
import {UrlService} from '../../services/url.service';
import {UserService} from '../../services/user.service';
import {StorageService} from '../../services/storage.service';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CurrentUser} from '../../models/current-user.model';
import {Store} from '@ngxs/store';
import {UpdateCurrentUser} from '../../../store/actions/current-user.actions';
import {Billing} from '../../models/billing.model';

@Component({
    selector: 'app-header-side',
    templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit, OnDestroy {
    @Input() notificPanel;
    public availableLangs = this.storageService.LANGUAGES;

    public egretThemes;
    public layoutConf: any;
    public avatar: string;
    private alive = true;
    currentUser$: Observable<CurrentUser>;
    public billing$: Observable<Billing>;
    private isStripeSubscriptionsRoute: Subject<boolean> = new BehaviorSubject(false);
    public isStripeSubscriptionsRoute$: Observable<boolean> = this.isStripeSubscriptionsRoute.asObservable();
    public readonly stripeSubscriptionsRoute = '/subscribe';

    constructor(
        private themeService: ThemeService,
        private layout: LayoutService,
        public translate: TranslateService,
        private renderer: Renderer2,
        public urlService: UrlService,
        private userService: UserService,
        public userDetailsService: UserDetailsService,
        private cdr: ChangeDetectorRef,
        private storageService: StorageService,
        private router: Router,
        private store: Store,
    ) {
        this.currentUser$ = this.store.select(state => state.currentUser.currentUser);
        this.billing$ = this.store.select(state => state.companyBilling.companyBilling);
        router.events.subscribe((val) => {
          if (val instanceof NavigationEnd) {
            this.isStripeSubscriptionsRoute.next(val.url === this.stripeSubscriptionsRoute);
          }
        });
    }

    ngOnInit() {
        this.egretThemes = this.themeService.egretThemes;
        this.layoutConf = this.layout.layoutConf;
        if (this.router.url) {
          this.isStripeSubscriptionsRoute.next(this.router.url === this.stripeSubscriptionsRoute);
        }
    }

    setLang(event) {
        this.store.dispatch(new UpdateCurrentUser({language: event.value}));
    }

    changeTheme(theme) {
        // this.themeService.changeTheme(theme);
    }

    toggleNotific() {
        this.notificPanel.toggle();
    }

    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full'
            })
        }
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed'
        });
    }

    toggleCollapse() {
        // compact --> full
        if (this.layoutConf.sidebarStyle === 'compact') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
                sidebarCompactToggle: false
            }, {transitionClass: true})
        }

        // * --> compact
        this.layout.publishLayoutChange({
            sidebarStyle: 'compact',
            sidebarCompactToggle: true
        }, {transitionClass: true})

    }

    onSearch(e) {
        //   console.log(e)
    }

    ngOnDestroy() {
        this.alive = false;
    }

    logout() {
        this.userService.logout();
    }
}
