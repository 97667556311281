import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {StorageService} from '../services/storage.service';
import {UrlService} from '../services/url.service';
import {Observable} from 'rxjs/Rx';
import {Actions, ofActionSuccessful} from '@ngxs/store';
import {UpdateCurrentUser} from '../../store/actions/current-user.actions';
import {filter, take} from 'rxjs/operators';


@Injectable()
export class AdminLayoutGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private urlService: UrlService,
    private _actions: Actions
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    if (this.storageService.userID) {

      // subscription awaits first currentUser value getting from backend, navigates to settings if name is empty
      this._actions
      .pipe(
        ofActionSuccessful(UpdateCurrentUser),
        filter(action => action.payload.id),
        take(1)
      )
      .subscribe(({ payload }) => {
        if (!payload.first_name || !payload.last_name) {
          this.urlService.navigateToProfileSettings();
        }
      });

      return true;
    }
    this.urlService.navigateToUserSignin();
    return false;
  }

}
