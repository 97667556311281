import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import * as am4core from '@amcharts/amcharts4/core';
import LanguageColorData from './language-color-data';
import {Subject, Subscribable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {DashboardPeriod, DashboardPeriodAndPeriodSelection} from '../../shared/models/dashboard/dashboard-period.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DateAdapter, SatDatepickerRangeValue} from 'saturn-datepicker';
import { Location } from '@angular/common';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Store} from '@ngxs/store';
import {TranslateService} from '@ngx-translate/core';
import {ColorSource} from '../../shared/models/dashboard/color-source.model';
import {EntityActivity, EntityActivityGantt} from '../../shared/models/dashboard/activity.model';
import {ErrorHandlingUtilsService} from '../../shared/services/error-handling-utils.service';
import {
  GroupListDashboardData,
  groupListDashboardDataDefault,
  MemberDashboardData, MemberListDashboardData, MemberProjectDashboardData,
  memberDashboardDataDefault, memberListDashboardDataDefault, memberProjectDashboardDataDefault,
  ProjectDashboardData,
  projectDashboardDataDefault,
  ProjectListDashboardData,
  projectListDashboardDataDefault
} from '../../shared/models/dashboard/dashboard-data.model';
import {UtilsService} from '../../shared/services/utils.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    public memberNameSource = new Subject<string>();
    public projectNameSource = new Subject<string>();
    public groupNameSource = new Subject<string>();
    memberName$ = this.memberNameSource.asObservable();
    projectName$ = this.projectNameSource.asObservable();
    groupName$ = this.groupNameSource.asObservable();
    public readonly other_category_color = '#999';
    public maxDate = moment.utc();
    public minDate = moment.utc().subtract(1, 'years');
    public dates_range: SatDatepickerRangeValue<any>;
    public currGanttDate: string;
    public selectedWeekStart: Moment;
    public readonly periodOptions = [
        {disp: 'This week', param: 'this_week'},
        {disp: 'Previous week', param: 'previous_week'},
        {disp: 'Today', param: 'today'},
        {disp: 'Yesterday', param: 'yesterday'},
        {disp: 'This month', param: 'this_month'},
        {disp: 'Previous month', param: 'previous_month'}
    ];
    public defaultPeriodSelection = this.periodOptions[0].param;
    public periodSelection = this.defaultPeriodSelection;
    public readonly dateRangeOptionDisp = 'Custom dates range...';
    public readonly dateRangeOptionParam = 'dates_range';

    public showUserLimitMessage: boolean;
    public needPeriodInit = true;
    public needGanttDateInit = true;

    // Two arrays below contain contrasting colors (for human eye)
    private hslSmoothHueDiff = [ // Less colors (15) and a few dark colors, but nice hue distribution
        [0.056, 1, 0.6], [0.111, 1, 0.5], [0.167, 1, 0.8], [0.222, 1, 0.35], [0.306, 1, 0.45], [0.389, 1, 0.8],
        [0.472, 1, 0.35], [0.556, 1, 0.5], [0.611, 1, 0.8], [0.722, 1, 0.65], [0.778, 1, 0.8],
        [0.833, 1, 0.65], [0.889, 1, 0.5], [0.944, 1, 0.8], [1.0, 1, 0.55]
    ];
    private hslMaxContrast = [ // More colors (24), high lightness
        [0.027, 1.0, 0.747], [0.037, 0.446, 0.453], [0.081, 1.0, 0.702], [0.088, 0.375, 0.592], [0.09, 1.0, 0.498],
        [0.127, 1.0, 0.7], [0.15, 1.0, 0.504], [0.187, 0.453, 0.351], [0.206, 1.0, 0.727], [0.224, 0.421, 0.702],
        [0.354, 1.0, 0.363], [0.417, 1.0, 0.824], [0.454, 1.0, 0.341], [0.463, 1.0, 0.5], [0.487, 0.401, 0.692],
        [0.514, 1.0, 0.306], [0.531, 1.0, 0.502], [0.552, 1.0, 0.355], [0.565, 1.0, 0.5], [0.644, 1.0, 0.871],
        [0.686, 0.111, 0.459], [0.835, 1.0, 0.825], [0.941, 0.307, 0.655], [0.944, 1.0, 0.727]
    ];

    private hslSortedColors = this.hslSmoothHueDiff;
    private availableHSLColors: number[][];
    colorsInUse: object = {};

    private static hslToRgb(h: number, s: number, l: number): number[] {
        let r, g, b;

        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            function hue2rgb(p, q, t) {
                if (t < 0) { t += 1; }
                if (t > 1) { t -= 1; }
                if (t < 1 / 6) { return p + (q - p) * 6 * t; }
                if (t < 1 / 2) { return q; }
                if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
                return p;
            }

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;

            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
    }

    private static hslFromString(str: string): number[] {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line:no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash); hash = hash & hash;
        }

        const l = 0.60;
        // tslint:disable-next-line:no-bitwise
        const h = ((hash >> (8)) & 255) / 255; // 0 to 1
        // tslint:disable-next-line:no-bitwise
        const s = ((hash >> (2 * 8)) & 255) / 255 * 0.4 + 0.50; // 0.5 - 0.9 %

        return [h, s, l];
    }

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private color: LanguageColorData,
        private store: Store,
        private translate: TranslateService,
        private errorHandlingService: ErrorHandlingUtilsService,
        private utils: UtilsService) {
    }

    getGroupGanttDashboard(date: string): Subscribable<Object> {
      const params = this.createGanttParams(date);
      return this.http.get('/api/dashboard/group/intervals', {params: params}).pipe(
        catchError(err => this.handleHttpError(err, []))
      );
    }

    getMemberGanttDashboard(date: string, memberID: number): Subscribable<Object> {
      const params = this.createGanttParams(date);
      return this.http.get('/api/dashboard/member/' + memberID + '/intervals', {params: params}).pipe(
        catchError(err => this.handleHttpError(err, []))
      );
    }

    getProjectMemberGanttDashboard(date: string, projectID: number, memberID: number): Subscribable<Object> {
      let params = this.createGanttParams(date);
      params = params.set('member_id', String(memberID));
      return this.http.get('/api/dashboard/project/' + projectID + '/member_intervals', {params: params}).pipe(
        catchError(err => this.handleHttpError(err, []))
      );
    }

    getMemberDashboard(period: DashboardPeriod, member_id: number) {
        const params = this.createDashboardPeriodParams(period);
        return this.http.get<MemberDashboardData>(
            '/api/dashboard/member/' + member_id,
            {params: params}
        ).pipe(
          tap(res => {
              this.memberNameSource.next(res['name']);
          }),
          catchError(err => this.handleHttpError(err, memberDashboardDataDefault))
        );
    }

    getProjectListDashboard(period: DashboardPeriod) {
        const params = this.createDashboardPeriodParams(period);
        return this.http.get<ProjectListDashboardData>('/api/dashboard/project', {params: params}).pipe(
          catchError(err => this.handleHttpError(err, projectListDashboardDataDefault))
        );
    }

    getProjectDashboard(project_id: string, period: DashboardPeriod) {
        const params = this.createDashboardPeriodParams(period);
        return this.http.get<ProjectDashboardData>('/api/dashboard/project/' + project_id, {params: params}).pipe(
          tap(res => {
            this.projectNameSource.next(res['project_name']);
          }),
          catchError(err => this.handleHttpError(err, projectDashboardDataDefault))
        );
    }

    getProjectMemberDashboard(project_id: number, period: DashboardPeriod, member_id?: number) {
        let params = this.createDashboardPeriodParams(period);
        params = params.set('member_id', String(member_id));
        return this.http.get<MemberProjectDashboardData>(
            '/api/dashboard/project/' + project_id + '/member',
            {params: params}
        ).pipe(
          tap(res => {
            this.memberNameSource.next(res['member_name']);
            this.projectNameSource.next(res['project_name']);
          }),
          catchError(err => this.handleHttpError(err, memberProjectDashboardDataDefault))
        );
    }

    getTeamDashboard(period: DashboardPeriod, group_id?: number) {
        const params = this.createDashboardPeriodParams(period);
        let urlPath;
        if (group_id) {
            urlPath = '/api/dashboard/group/' + group_id;
        } else {
            urlPath = '/api/dashboard/group/all';
        }
        return this.http.get<MemberListDashboardData>(urlPath, {params: params}).pipe(
          tap(res => {
            this.groupNameSource.next(res['name']);
          }),
          catchError(err => this.handleHttpError(err, memberListDashboardDataDefault))
        );
    }

    getGroupListDashboard(period: DashboardPeriod) {
        const params = this.createDashboardPeriodParams(period);
        return this.http.get<GroupListDashboardData>('/api/dashboard/group', {params: params}).pipe(
          catchError(err => this.handleHttpError(err, groupListDashboardDataDefault))
        );
    }

    /** Returns HTTP params with period data appended. If period's type is date boundaries, also converts it to ISO */
    createDashboardPeriodParams(period: DashboardPeriod): HttpParams {
        let params = new HttpParams();
        if (period.period) {
            params = params.set('period', period.period);
        } else if (period.from && period.to) {
            // slice to remove 'Z' symbol from ISO string for correct backend processing
            params = params
              .set('from', moment.utc(period.from)
              .toISOString()
              .slice(0, -1));
            params = params
              .set('to', moment.utc(period.to)
              .add(1, 'days')
              .subtract( 1, 'seconds')
              .toISOString()
              .slice(0, -1));
        }
        return params;
    }

    createGanttParams(date: string) {
      let params = new HttpParams();
      params = params.set(
        'gantt_from',
        moment.utc(date).toISOString().slice(0, -1)
      );
      params = params.set(
        'gantt_to',
        moment.utc(date).add(1, 'days').subtract( 1, 'seconds').toISOString().slice(0, -1)
      );
      return params;
    }

    findClosestHueIndex(hue: number, hslColors: number[][]) {
        let minDiff = 1;
        let resultIndex;

        for (const index of hslColors.keys()) {
            const currDiff = Math.abs(hue - hslColors[index][0]);
            if (currDiff <= minDiff) {
                minDiff = currDiff;
                resultIndex = index;
            } else {
                break;
            }
        }
        return resultIndex;
    }

    public colorFromString(str: string, contrastColor?: boolean): am4core.Color {
        if (!str || str.length === 0 || str === 'N/A') {
            return am4core.color({r: 100, g: 100, b: 100});
        }
        let hsl = DashboardService.hslFromString(str);
        if (contrastColor && this.availableHSLColors.length > 0) {
            const index = this.findClosestHueIndex(hsl[0], this.availableHSLColors);
            hsl = this.availableHSLColors.splice(index, 1)[0];
        }

        const rgb = DashboardService.hslToRgb(hsl[0], hsl[1], hsl[2]);
        return am4core.color({r: rgb[0], g: rgb[1], b: rgb[2]});
    }

    public getColorForLanguage(language: string): am4core.Color {
        const lower_language = language.toLowerCase();
        const github_color = this.color.data[lower_language];
        if (github_color) {
            return am4core.color(github_color);
        } else {
            return this.colorFromString(lower_language);
        }
    }

    getColorBoundToEntity(entityName: string): am4core.Color {
      const color = this.colorFromString(entityName, true);
      if (!this.colorsInUse.hasOwnProperty(entityName)) {
        this.colorsInUse[entityName] = color;
      }
      return this.colorsInUse[entityName];
    }

    /** Methods with "this" bound to DashboardService (makes method passable as an argument)
    * More about "this":
    * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-does-this-get-orphaned-in-my-instance-methods */
    // for getting colors data
    public boundGetColorForLanguage: ColorSource = (s: string) => this.getColorForLanguage(s);
    public boundGetColorBoundToEntity: ColorSource = (s: string) => this.getColorBoundToEntity(s);
    public boundColorFromString: ColorSource = (s: string) => this.colorFromString(s);

    // for getting gantt data
    public boundGetMemberGanttDashboard = (date: string, memberID: number): Subscribable<Object> => {
      return this.getMemberGanttDashboard(date, memberID);
    }
    public boundGetGroupGanttDashboard = (date: string): Subscribable<Object> => {
      return this.getGroupGanttDashboard(date);
    }
    public boundGetProjectMemberGanttDashboard = (
      date: string, projectID: number, memberID: number
    ): Subscribable<Object> => {
      return this.getProjectMemberGanttDashboard(date, projectID, memberID);
    }

    /** Returns UTC Moment object if date string is valid */
    convertStrToUTCMomentIfValid(aString: string) {
        const date = moment.utc(aString);
        if (date.isValid()) {
            return date;
        } else {
            console.error('Invalid date input');
        }
    }

    /** Checks whether a period belongs to hardcoded periodOptions array */
    isPeriodValid(period: string) {
        return this.periodOptions.some(x => x.param === period);
    }

    /** Check date boundaries correctness. */
    isDateBoundariesValid(from: Moment, to: Moment) {
        const isFromValid = from.isBetween(this.minDate, to, 'day', '[]');
        const isToValid = from.isBetween(from, this.maxDate, 'day', '[]');
        return isFromValid && isToValid;
    }

    /** Returns true if current period selection is set to 'Dates range' */
    isDateRange() {
        return this.periodSelection === this.dateRangeOptionParam;
    }

    /** Assigns specific period string to shared periodSelection option and set it to QueryParams */
    setPeriod(period: string) {
        this.periodSelection = period;
    }

    /** Assigns specific dates range to shared variable and set it to QueryParams */
    setDatesRange(range: SatDatepickerRangeValue<any>) {
        this.periodSelection = this.dateRangeOptionParam;
        this.dates_range = range;
    }

    setGanttDate(ganttDate: string) {
        this.currGanttDate = ganttDate;
    }

    /** Checks QueryParams validity and assigns it to shared variables */
    readQueryParams(route: ActivatedRoute) {
        const period = route.snapshot.queryParams.period;
        if (period && this.isPeriodValid(period)) {
            this.periodSelection = period;
        }
        let from = route.snapshot.queryParams.from;
        if (from) {
            from = this.convertStrToUTCMomentIfValid(route.snapshot.queryParams.from);
        }
        let to = route.snapshot.queryParams.to;
        if (to) {
            to = this.convertStrToUTCMomentIfValid(route.snapshot.queryParams.to);
        }
        if (from && to && this.isDateBoundariesValid(from, to)) {
            this.dates_range = {begin: from, end: to};
            this.periodSelection = this.dateRangeOptionParam;
        }
    }

    /** Checks period and dates variables validity and assigns it QueryParams */
    setQueryParams(router: Router, route: ActivatedRoute, location: Location) {
        const params = {
            period: null,
            from: null,
            to: null,
            gantt: null
        };

        // Period params
        if (this.isDateRange() && this.dates_range) {
            params.from = this.dates_range.begin.format(StorageService.datesStorageFormat);
            params.to = this.dates_range.end.format(StorageService.datesStorageFormat);
        } else if (this.isPeriodValid(this.periodSelection)) {
            params.period = this.periodSelection;
        } else {
            params.period = this.defaultPeriodSelection;
        }

        // Gantt params
        if (this.currGanttDate) {
            params.gantt = this.currGanttDate;
        }

        const urlTree = router.createUrlTree([], {
           relativeTo: route,
           queryParams: params,
           queryParamsHandling: 'merge',
        });
        location.replaceState(urlTree.toString());
    }

    /** Returns specific Dashboard period if shared variables are set and valid, else returns invalid Dashboard period */
    getDashboardPeriod(): DashboardPeriod {
        let dashboardPeriod: DashboardPeriod = {isValid: false};
        if (this.isDateRange() && this.dates_range) {
            dashboardPeriod = { from: this.dates_range.begin, to: this.dates_range.end, isValid: true };
        } else if (this.isPeriodValid(this.periodSelection)) {
            dashboardPeriod = { period: this.periodSelection, isValid: true };
        }

        return dashboardPeriod;
    }

    /** Initializes dashboard period if it is not initialized */
    initializePeriodIfNeeded(route: ActivatedRoute) {
        if (this.needPeriodInit) {
            this.readQueryParams(route);
            this.needPeriodInit = false;
        }
    }

     /** Initializes gantt date if it is not initialized */
    initializeGanttDateIfNeeded(dates: Array<string>, route: ActivatedRoute) {
        if (this.needGanttDateInit) {
            const date = route.snapshot.queryParams.gantt;
            const index = this.getValidGanttIndex(dates, date);
            this.currGanttDate = dates[index];
            this.needGanttDateInit = false;
        }
    }

    /** Checks if gantt date is valid. If it is - returns it's index, otherwise - returns index of last date */
    getValidGanttIndex(dates: Array<string>, date: string) {
        let index = dates.indexOf(date);
        if (index === -1) {
            index = dates.length - 1;
        }
        return index;
    }

    /** Changes language and week start day of the calendars */
    adjustCalendarSettings (adapter: DateAdapter<any>) {
        const store = this.store.snapshot();
        adapter.setLocale(store.currentUser.currentUser.language);
        adapter.getFirstDayOfWeek = () => {
            return (1 - store.currentUser.currentUser.week_start_day);
        };
    }

    public refreshAvailableColors() {
        this.availableHSLColors = [...this.hslSortedColors];
    }

    /** Converts date string from storage to display format*/
    formatDateString (dateString: string) {
        const converted = moment.utc(dateString);
        if (converted.isValid()) {
            return converted.locale(this.translate.currentLang).format(StorageService.datesDisplayFormat);
        }
    }

    compareValues(key: string, order = 'asc') {
        return (a, b) => {
            let comparison = 0;
            if (a[key] > b[key]) {
                comparison = 1;
            } else if (a[key] < b[key]) {
                comparison = -1;
            }
            return (order === 'desc') ? (comparison * -1) : comparison;
        };
    }

    public convertNumberToTimeString(total_minutes: number, noLetters= false): string {
        return this.utils.convertNumberToTimeString(total_minutes, noLetters);
    }

    getEntityName(entity: EntityActivity | EntityActivityGantt): string {
        return entity.has_owner === true ? entity.name + this.translate.instant(' (personal)') : entity.name;
    }

    private handleHttpError(error: HttpErrorResponse, defaultValue: any) {
      return this.errorHandlingService.handleHttpError(error, defaultValue);
    }

    public convertDurationToMinutes(duration: string): number {
        return moment.duration(duration).asMinutes();
    }

    public convertMinutesToDuration(minutes: number): string {
        const m = moment.duration(minutes, 'minutes');
        return [
            m.get('hours').toString(),
            m.get('minutes').toString(),
            m.get('seconds').toString(),
          ].join(':');
    }
}
