<mat-toolbar class="topbar">
    <!-- Sidenav toggle button -->
    <button
            *ngIf="layoutConf.sidebarStyle !== 'compact'"
            mat-icon-button
            id="sidenavToggle"
            (click)="toggleSidenav()"
            matTooltip="Toggle Hide/Open"
    >
        <mat-icon>menu</mat-icon>
    </button>

    <!-- Search form -->
    <!-- <div fxFlex fxHide.lt-sm="true" class="search-bar">
      <form class="top-search-form">
        <mat-icon role="img">search</mat-icon>
        <input autofocus="true" placeholder="Search" type="text" />
      </form>
    </div> -->
    <span fxFlex></span>
<!--TODO: Uncomment when stripe settings will be ready-->
<!--    <ng-container *ngIf="!(isStripeSubscriptionsRoute$ | async)">-->
<!--      <button routerLink="{{stripeSubscriptionsRoute}}" mat-raised-button class="mat-primary" style="margin-right: 20px"-->
<!--              *ngIf="(billing$ | async).id; else upgradeButton">-->
<!--        {{ 'Manage your plan' | translate }}-->
<!--      </button>-->
<!--      <ng-template #upgradeButton>-->
<!--        <button routerLink="{{stripeSubscriptionsRoute}}" mat-raised-button class="mat-accent" style="margin-right: 20px">-->
<!--          {{ 'Buy' | translate }}-->
<!--        </button>-->
<!--      </ng-template>-->
<!--    </ng-container>-->

    <!-- Language Switcher -->
    <language-switcher id="langToggle" class="topbar-button-right"></language-switcher>

    <!-- Top left user menu -->
    <button
            mat-icon-button
            [matMenuTriggerFor]="accountMenu"
            class="topbar-button-right img-button"
    >
        <img [src]="(currentUser$ | async).avatar" alt=""/>
    </button>

    <mat-menu #accountMenu="matMenu">
        <button mat-menu-item [routerLink]="['/profile/settings']" type="button">
            <mat-icon>settings</mat-icon>
            <span>{{ 'Account Settings' | translate }}</span>
        </button>
        <button mat-menu-item (click)="logout()" type="button">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'Sign out' | translate }}</span>
        </button>
    </mat-menu>
</mat-toolbar>
