import {ProjectItem} from '../../shared/models/project.model';

export class UpdateProjects {
  static readonly type = '[Projects] Update';

  constructor(public payload: ProjectItem[]) {}
}

export class UpdateProjectItem {
  static readonly type = '[Projects] Update Item';

  constructor(public payload: ProjectItem) {}
}

export class FilterStringEntered {
  static readonly type = '[Projects] Filter';

  constructor(public payload: string) {
  }
}
