import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {StorageService} from '../services/storage.service';
import * as moment from 'moment';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(date: Moment | string, format?: 'date' | 'datetime'): string {
    let displayFormat = StorageService.datesDisplayFormat;
    if (format === 'datetime') {
      displayFormat = StorageService.dateTimeDisplayFormat;
    }
    const momentToTransform = typeof date === 'string' ? moment(date) : date;
    return momentToTransform.locale(this.translate.currentLang).format(displayFormat);
  }

}
