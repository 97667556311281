import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private translate: TranslateService) { }

  /** Returns time in '12:34' format if noLetters == true.
  * Returns time in '12h 34m' localized format if noLetters == false */
  public convertNumberToTimeString(total_minutes: number, noLetters= false): string {
      const hours = Math.floor(total_minutes / 60);
      const minutes = Math.round(total_minutes - hours * 60);
      if (noLetters) {
        return hours.toString() + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes.toString());
      } else {
        return (hours !== 0 ? hours.toString() + this.translate.instant('h ') : '') +
          minutes.toString() + this.translate.instant('m');
      }
  }
}
