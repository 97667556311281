
export const environment = {
   production: true,
   apiURL: 'productionApi',
   sentryDsn: '',
   stripeApiKey: 'pk_test_51Ht9RUEaUrzegpW3Pn1huv6WZzw7UhqUpg0owWq4kEe5RId7s5CrBWaqLZlJ5FN6DkkBpDjvtdCHk4yG1dX68gcv00VKAbNxX9',
   stripePlans: {
     standard: '',
     premium: 'prod_J61hDYRKRejYJj',
   }
};
