import { Injectable } from '@angular/core';
import {DialogWindowComponent} from '../components/dialog-window/dialog-window.component';
import {MatDialog} from '@angular/material/dialog';

export interface DialogWindowParams {
  title?: string;
  message?: string;
  okButtonText?: string;
}

export interface CancellableDialogWindowParams extends DialogWindowParams {
  cancelButtonText?: string;
}

const defaultDialogWindowParams: DialogWindowParams = {
  title: '',
  message: '',
  okButtonText: 'OK'
};

const defaultCancellableDialogWindowParams: CancellableDialogWindowParams = {
  ...defaultDialogWindowParams,
  cancelButtonText: 'Cancel'
};

@Injectable({
  providedIn: 'root'
})
export class PopupWindowsService {

  constructor(public dialog: MatDialog) { }

  public openCancellableDialogWindow(params?: CancellableDialogWindowParams) {
    const data = {...defaultCancellableDialogWindowParams, ...params};
    return this.dialog.open(DialogWindowComponent, {data: data, maxWidth: '500px'});
  }

  public openDialogWindow(params?: DialogWindowParams) {
    const data = {...defaultDialogWindowParams, ...params};
    return this.dialog.open(DialogWindowComponent, {data: data, maxWidth: '500px'});
  }

  public openErrorDialogWindow(params?: DialogWindowParams) {
    const data = {
      ...defaultDialogWindowParams,
      title: params?.message ? 'Error' : 'Unexpected error',
      ...params
    };
    return this.dialog.open(DialogWindowComponent, {data: data, maxWidth: '500px'});
  }

  showTooltip(tooltip) {
    tooltip.show();
    setTimeout(() => tooltip.hide(), 700);
  }
}
