import {MemberDirectory} from '../../shared/models/directory.model';

export class UpdateDirectories {
    static readonly type = '[Directories] Update';

    constructor(public payload: MemberDirectory[]) {}
}

export class UpdateDirectoryItem {
    static readonly type = '[Directories] Update Item';

    constructor(public payload: MemberDirectory) {}
}

export class DeleteDirectoryItem {
    static readonly type = '[Directories] Delete Item';

    constructor(public payload: number) {}
}

export class UpdateSelectedDirectory {
    static readonly type = '[Directories] Updated Selected';

    constructor(public payload: MemberDirectory) {
    }
}

