import {MemberGroup} from '../../shared/models/group.model';
import {AccessPolicy} from '../../shared/models/group/access-policy.model';
import {MemberItem} from '../../shared/models/member.model';

export class UpdateGroups {
    static readonly type = '[Groups] Update';

    constructor(public payload: MemberGroup[]) {}
}

export class UpdateGroupItem {
    static readonly type = '[Groups] Update Item';

    constructor(public payload: MemberGroup) {}
}

export class UpdateSelectedGroup {
    static readonly type = '[Groups] Update Selected';

    constructor(public payload: MemberGroup) {
    }
}

export class UpdateCertainPolicyInSelectedGroup {
    static readonly type = '[Groups] Update Certain Policy In Selected Group';

    constructor(public payload: AccessPolicy) {
    }
}

export class AddPolicyToSelectedGroup {
    static readonly type = '[Groups] Add Policy To Selected Group';

    constructor(public payload: AccessPolicy) {
    }
}

export class RemovePolicyFromSelectedGroup {
    static readonly type = '[Groups] Remove Policy From Selected Group';

    constructor(public payload: number) {
    }
}

export class AddMemberToSelectedGroup {
    static readonly type = '[Groups] Add Member To Selected Group';

    constructor(public payload: MemberItem) {
    }
}

export class RemoveMemberFromSelectedGroup {
    static readonly type = '[Groups] Remove Member From Selected Group';

    constructor(public payload: number) {
    }
}
