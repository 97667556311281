import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Billing, BillingUpdatingPayload} from '../models/billing.model';
import * as moment from 'moment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class CompanyBillingService {

  private static convertDatesToMoments(billing: Billing) {
    billing.subscription_start = moment(billing.subscription_start);
    billing.subscription_end = moment(billing.subscription_end);
    billing.next_invoice_date = moment(billing.next_invoice_date);
    return billing;
  }

  constructor(
      private http: HttpClient,
  ) {
  }

  public getCompanyBilling() {
    return this.http.get('/api/company-billing').pipe(map((res: Billing) => {
      return CompanyBillingService.convertDatesToMoments(res);
    }));
  }

  public editCompanySubscription(payload: BillingUpdatingPayload) {
    return this.http.patch('/api/company-billing', payload).pipe(map((res: Billing) => {
      return CompanyBillingService.convertDatesToMoments(res);
    }));
  }

  public createSetupIntentClientSecret() {
    return this.http.post('/api/payment-intent/setup_card', {});
  }

  public createPaymentIntent(payload) {
    return this.http.post('/api/payment-intent', payload);
  }

  public voidInvoice(invoiceID: string) {
    return this.http.delete('/api/payment-intent/void_invoice', {params: {id: invoiceID}});
  }
}
