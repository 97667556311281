import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {of, throwError} from 'rxjs';
import {StorageService} from './storage.service';
import {UrlService} from './url.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingUtilsService {

  constructor(
    private storageService: StorageService,
    private urlService: UrlService,
    private router: Router
  ) { }

  public handleHttpError(error: HttpErrorResponse, defaultValue: any = null) {
    if (error.status === 401) {
      this.storageService.deleteCurrentUserId();
      this.urlService.navigateToUserSignin();
      return of(defaultValue);
    }
    if (error.status === 403) {
      this.router.navigate(['sessions/403'], { skipLocationChange: true });
      return of(defaultValue);
    }
    if (error.status === 404) {
      this.router.navigate(['sessions/404'], { skipLocationChange: true });
      return of(defaultValue);
    }
    return throwError(error);
  }
}
