import {MemberItem} from '../../shared/models/member.model';
import {AccessPolicy} from '../../shared/models/group/access-policy.model';
import {MemberGroup} from '../../shared/models/group.model';

export class UpdateMembers {
    static readonly type = '[Members] Update';

    constructor(public payload: MemberItem[]) {}
}

export class UpdateMemberItem {
    static readonly type = '[Members] Update Item';

    constructor(public payload: MemberItem) {}
}

export class UpdateMembersTotal {
    static readonly type = '[Members] Update Total';

    constructor(public payload: number) {}
}

export class SetSelectedMember {
    static readonly type = '[Members] Set';

    constructor(public payload: MemberItem) {
    }
}

export class UpdateCertainPolicyInSelectedMember {
    static readonly type = '[Members] Update Certain Policy In Selected Member';

    constructor(public payload: AccessPolicy) {
    }
}

export class AddPolicyToSelectedMember {
    static readonly type = '[Members] Add Policy To Selected Member';

    constructor(public payload: AccessPolicy) {
    }
}

export class RemovePolicyFromSelectedMember {
    static readonly type = '[Members] Remove Policy From Selected Member';

    constructor(public payload: number) {
    }
}

export class AddGroupToSelectedMember {
    static readonly type = '[Members] Add Group To Selected Member';

    constructor(public payload: MemberGroup) {
    }
}

export class RemoveGroupFromSelectedMember {
    static readonly type = '[Members] Remove Group From Selected Member';

    constructor(public payload: MemberGroup) {
    }
}
