import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {RoutePartsService} from "./shared/services/route-parts.service";
import {filter, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './shared/services/storage.service';
// import { ThemeService } from './is_shared/services/theme.service';
// import { LayoutService } from './is_shared/services/layout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
    appTitle = 'Team Tracker';
    pageTitle = '';

    constructor(
        public title: Title,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
        private translate: TranslateService,
        private storageService: StorageService
        // private themeService: ThemeService,
        // private layout: LayoutService,
        // private renderer: Renderer2
    ) {
        translate.onLangChange.pipe(tap((event) => {
          this.storageService.currLang = event.lang;
        })).subscribe();
        const browserLang: string = translate.getBrowserLang();
        const availableLangs = storageService.getLangCodes();
        const defaultLang = 'en';
        translate.addLangs(availableLangs);
        translate.setDefaultLang(defaultLang);
        let currLang: string;
        if (storageService.currLang) {
          currLang = storageService.currLang;
        } else if (availableLangs.includes(browserLang)) {
          currLang = browserLang;
        } else {
          currLang = defaultLang;
        }
        translate.use(currLang);
    }

    ngOnInit() {
        this.changePageTitle();
    }

    ngAfterViewInit() {
    }

    changePageTitle() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
            var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length)
                return this.title.setTitle(this.appTitle);
            // Extract title from parts;
            this.pageTitle = routeParts
                .reverse()
                .map((part) => part.title)
                .reduce((partA, partI) => {
                    return `${partA} > ${partI}`
                });
            this.pageTitle += ` | ${this.appTitle}`;
            this.title.setTitle(this.pageTitle);
        });
    }

}
