export interface Company {
    id?: number;
    schema_name?: string;
    name?: string;
    domains?: Domain[];
    max_users?: number;
}

export interface Domain {
    id?: number;
    domain?: string;
    is_main_domain?: boolean;
    has_admin?: boolean;
}

export const companyDefault: Company = {
  id: null,
  schema_name: '',
  name: '',
  domains: [],
  max_users: null
};
