import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {catchError} from 'rxjs/operators';
import {CurrentUser, currentUserDefault} from '../models/current-user.model';
import {ErrorHandlingUtilsService} from './error-handling-utils.service';

@Injectable({
    providedIn: 'root'
})

// Class for user related logic with a standard interceptable HttpClient
export class UserDetailsService {

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
        private errorHandlingService: ErrorHandlingUtilsService
    ) {
    }

    public get_current_user(handleHttpErrors= true) {
      const query = this.http.get('/api/current_user');
      if (handleHttpErrors) {
        return query.pipe(
          catchError(err => this.handleHttpError(err, currentUserDefault))
        );
      } else {
        return query;
      }
    }

    public updateUser(user: CurrentUser) {
      return this.http.patch('/api/current_user', user).pipe(
          catchError(err => this.handleHttpError(err, currentUserDefault))
        );
    }

    updateUserImage(image: File) {
      const formData = new FormData();
      formData.append('avatar', image, image.name);
      return this.http.patch('/api/current_user', formData).pipe(
        catchError(err => this.handleHttpError(err))
      );
    }

    getAPIToken() {
      return this.http.get<{token: string}>('/api/api_token').pipe(
        catchError(err => this.handleHttpError(err, {token: ''}))
      );
    }
    flushAPIToken() {
      return this.http.put('/api/api_token', {}).pipe(
        catchError(err => this.handleHttpError(err))
      );
    }

    public getMessages() {
      return this.http.get('api/messages').pipe(
        catchError(err => this.handleHttpError(err))
      );
    }

    private handleHttpError(error: HttpErrorResponse, defaultValue?: any) {
      return this.errorHandlingService.handleHttpError(error, defaultValue);
    }

}
