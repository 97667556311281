import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {subscriptionInfoDefault} from '../models/subscription-info.model';
import {ErrorHandlingUtilsService} from './error-handling-utils.service';
import {PopupWindowsService} from './popup-windows.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionInfoService {
  public readonly SUBSCRIPTION_REQUIRED = {
    title: 'Subscription required',
    message: 'You need subscription in order to use this feature'
  };
  public readonly USER_LIMIT_REACHED = {
    title: 'You have reached your user limit',
    message: 'Extend your subscription to add more users'
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService: ErrorHandlingUtilsService,
    private popupWindowsService: PopupWindowsService
  ) {
  }

  public getSubscriptionInfo() {
    return this.http.get('/api/subscription-info').pipe(
      catchError(err => this.handleHttpError(err, subscriptionInfoDefault))
    );
  }

  public openSubscribeDialog(messageType: {title: string, message: string}) {
    const dialogParams = {
      title: messageType.title,
      message: messageType.message,
      okButtonText: 'See plans',
      cancelButtonText: 'No, thanks'
    };
    const dialogRef = this.popupWindowsService.openCancellableDialogWindow(dialogParams);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/subscribe/']);
      }
    });
  }
  private handleHttpError(error: HttpErrorResponse, defaultValue?: any) {
    return this.errorHandlingService.handleHttpError(error, defaultValue);
  }
}
