import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UrlService } from '../../shared/services/url.service';
import { Domain } from '../../shared/models/company.model';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class DomainCheckGuard implements CanActivate {
  constructor(private router: Router, private urlService: UrlService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.urlService.getCurrentDomain().pipe(
      map((domain: Domain) => {
        if (!domain.is_main_domain) {
          this.router.navigate(['/sessions/signin_user']);
          return false;
        }

        return true;
      }),
      catchError(error => {
        this.router.navigate(['/sessions/signup']);
        return of(false);
      })
    );
  }
}
