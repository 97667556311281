import {BillingUpdatingPayload} from '../../shared/models/billing.model';

export class UpdateCompanyBilling {
  static readonly type = '[Company Billing] Update';

  constructor(public payload: BillingUpdatingPayload) {}
}

export class LoadCompanyBilling {
  static readonly type = '[Company Billing] Load';

  constructor() {}
}

export class SetCompanyBillingBusy {
  static readonly type = '[Company Billing] Set Busy';

  constructor() {}
}

export class SetCompanyBillingFree {
  static readonly type = '[Company Billing] Set Free';

  constructor() {}
}
