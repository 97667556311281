import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {UrlService} from '../services/url.service';
import {Observable} from 'rxjs/Rx';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {CurrentUser} from '../models/current-user.model';
import {UpdateCurrentUser} from '../../store/actions/current-user.actions';
import {take} from 'rxjs/operators';


@Injectable()
export class DashboardGuard implements CanActivate {

  constructor(
    private urlService: UrlService,
    private store: Store,
    private _actions: Actions
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    const currUser: CurrentUser = this.store.snapshot().currentUser.currentUser;
    if (currUser.is_intro_help_first) {
      this.urlService.navigateToWelcomeInstructions();
      return false;
    } else {

      // subscription awaits first currentUser value getting from backend, navigates to welcome page if user is new
      this._actions
      .pipe(ofActionSuccessful(UpdateCurrentUser), take(1))
      .subscribe(({ payload }) => {
        if (payload.is_intro_help_first) {
          this.urlService.navigateToWelcomeInstructions();
        }
      });

      return true;
    }
  }
}
