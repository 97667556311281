import {AccessPolicy} from './group/access-policy.model';

export interface CurrentUser {
    id?: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    password?: string;
    email?: string;
    api_key?: string;
    avatar?: string;
    timezone?: string;
    language?: string;
    week_start_day?: number;
    working_day_end?: number;
    is_superuser?: boolean;
    authenticate_with_directory?: boolean;
    daily_reports_send_time_offset?: number;
    is_intro_help_first?: boolean;
    access_policies?: AccessPolicy[];
    group_access_policies?: AccessPolicy[];
    dashboards_timeout?: string;
}

export const currentUserDefault: CurrentUser = {
  id: null,
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  api_key: '',
  avatar: '',
  timezone: '',
  language: 'en',
  week_start_day: null,
  working_day_end: null,
  is_superuser: false,
  authenticate_with_directory: false,
  daily_reports_send_time_offset: null,
  is_intro_help_first: false,
  access_policies: [],
  group_access_policies: [],
  dashboards_timeout: ''
};
