import { State, Action, StateContext, Selector } from '@ngxs/store';
import {Injectable} from '@angular/core';
import {patch, removeItem, updateItem} from '@ngxs/store/operators';
import {
    DeleteDirectoryItem,
    UpdateDirectories,
    UpdateDirectoryItem,
    UpdateSelectedDirectory
} from '../actions/directories.actions';
import {MemberDirectory, memberDirectoryDefault} from '../../shared/models/directory.model';

export class DirectoriesStateModel {
    directories: MemberDirectory[];
    selectedDirectory: MemberDirectory;
    filterText: string;
}

@State<DirectoriesStateModel>({
    name: 'directories',
    defaults: {
        directories: null,
        selectedDirectory: memberDirectoryDefault,
        filterText: ''
    }
})

@Injectable()
export class DirectoriesState {

    @Selector()
    static getDirectories(state: DirectoriesStateModel) {
        return state.directories;
    }

    @Selector()
    static getSelectedDirectory(state: DirectoriesStateModel) {
        const selectedDirectory = state.selectedDirectory;
        // Adding SCIM endpoint if directory has already created
        if (selectedDirectory.id) {
            selectedDirectory.scim_endpoint = `https://${window.location.hostname}/api/member_directory/`
                + `${selectedDirectory.id}/scim/?AzureAdScimPatch062020`;
        }
        return selectedDirectory;
    }

    @Action(UpdateDirectories)
    updateDirectories({getState, patchState }: StateContext<DirectoriesStateModel>, { payload }: UpdateDirectories) {
        patchState({
            directories: payload
        });
    }

    @Action(UpdateDirectoryItem)
    updateDirectoryItem({getState, setState }: StateContext<DirectoriesStateModel>, { payload }: UpdateDirectoryItem) {
        // filtering null fields recieved from server
        Object.keys(payload).forEach((key) => (
            payload[key] == null) && delete payload[key]
        );
        setState(
            patch(
            {directories: updateItem(item => item.id === payload.id,  payload )}
            )
        );
    }

    @Action(DeleteDirectoryItem)
    deleteDirectoryItem({getState, setState }: StateContext<DirectoriesStateModel>, { payload }: DeleteDirectoryItem) {
        setState(
            patch(
            {directories: removeItem(item => item.id === payload )}
            )
        );
    }

    @Action(UpdateSelectedDirectory)
    updateSelectedDirectory(
        {getState, setState }: StateContext<DirectoriesStateModel>,
        { payload }: UpdateSelectedDirectory
    ) {
        const state = getState();
        if (payload == null) {
            payload = memberDirectoryDefault;
        }
        // filtering null fields recieved from server
        Object.keys(payload).forEach((key) =>
            (payload[key] == null) && delete payload[key]
        );
        setState({
            ...state,
            selectedDirectory: payload
        });
    }
}


