/**
 * Dashboard endpoints models
 * @packageDocumentation
 */

import {EntityActivity, MemberActivity, EntityDayActivity} from './activity.model';

export interface ProjectListDashboardData {
  projects_total: Array<EntityActivity>;
  projects_daily: Array<EntityDayActivity>;
}

export const projectListDashboardDataDefault: ProjectListDashboardData = {
  projects_total: [],
  projects_daily: []
};

export interface ProjectDashboardData {
  members_daily: Array<EntityDayActivity>;
  members_total: Array<MemberActivity>;
  languages: Array<EntityActivity>;
  project_name: string;
}

export const projectDashboardDataDefault: ProjectDashboardData = {
  members_daily: [],
  members_total: [],
  languages: [],
  project_name: null
};

export interface MemberDashboardData {
  name: string;
  avatar: string;
  working_day_end: number;
  languages: Array<EntityActivity>;
  projects: Array<EntityActivity>;
  ides: Array<EntityActivity>;
  os: Array<EntityActivity>;
  projects_daily: Array<EntityDayActivity>;
  dates_with_data: Array<string>;
}

export const memberDashboardDataDefault: MemberDashboardData = {
  name: null,
  avatar: null,
  working_day_end: null,
  languages: [],
  projects: [],
  ides: [],
  os: [],
  projects_daily: [],
  dates_with_data: []
};

export interface MemberProjectDashboardData {
  member_name: string;
  project_name: string;
  working_day_end: number;
  languages: Array<EntityActivity>;
  files: Array<EntityActivity>;
  branches_daily: Array<EntityDayActivity>;
  dates_with_data: Array<string>;
}

export const memberProjectDashboardDataDefault: MemberProjectDashboardData = {
  member_name: null,
  project_name: null,
  working_day_end: null,
  languages: [],
  files: [],
  branches_daily: [],
  dates_with_data: []
};

export interface MemberListDashboardData {
  full_members: Array<MemberActivity>;
  full_members_daily: Array<EntityDayActivity>;
}

export const memberListDashboardDataDefault: MemberListDashboardData = {
  full_members: [],
  full_members_daily: []
};

export interface GroupListDashboardData {
  groups: Array<EntityActivity>;
  groups_daily: Array<EntityDayActivity>;
  dates_with_data: Array<string>;
}

export const groupListDashboardDataDefault: GroupListDashboardData = {
  groups: [],
  groups_daily: [],
  dates_with_data: []
};
