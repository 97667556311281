import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../services/storage.service';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {

  langs: {name: string; code: string; flag: string; }[];
  currLang: string;
  onLangChangeSub: Subscription;

  constructor(
    private translate: TranslateService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.langs = this.storageService.LANGUAGES;
    this.currLang = this.translate.currentLang;
    this.onLangChangeSub = this.translate.onLangChange.pipe(tap((event) => {
      this.currLang = event.lang;
    })).subscribe();
  }

  public switchLang(event) {
    this.translate.use(event.value);
  }

  ngOnDestroy(): void {
    this.onLangChangeSub.unsubscribe();
  }

}
