<mat-select
  name="currentLang"
  [ngModel] = "currLang"
  (selectionChange)="switchLang($event)"
  disableOptionCentering
  >
  <mat-option
    *ngFor="let lang of langs"
    [value]="lang.code"
    ngDefaultControl
  >
    <span [ngClass]="[lang.flag, 'flag-icon', 'mr-05']"></span>
    {{ lang.code.toUpperCase() }}
  </mat-option>
</mat-select>
