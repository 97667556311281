import {Plan, Price, planDefault, priceDefault} from './plan.model';
import {Moment} from 'moment';
import {Company, companyDefault} from './company.model';

export interface Billing {
  id?: number;
  plan?: Plan;
  price?: Price;
  company?: Company;
  subscription_start?: Moment;
  subscription_end?: Moment;
  subscription_cancel_at_period_end?: boolean;
  card_last4?: number;
  card_brand?: string;
  card_exp_year?: number;
  card_exp_month?: number;
  next_invoice_date?: Moment;
  next_invoice_amount?: number;
}

export const billingDefault: Billing = {
  id: null,
  plan: planDefault,
  price: priceDefault,
  company: companyDefault,
  subscription_start: null,
  subscription_end: null,
  subscription_cancel_at_period_end: false,
  card_last4: null,
  card_brand: '',
  card_exp_year: null,
  card_exp_month: null,
  next_invoice_date: null,
  next_invoice_amount: null
};

export interface BillingUpdatingPayload {
  quantity?: number;
  subscription_cancel_at_period_end?: boolean;
  price_id?: string;
  payment_method_id?: string;
}
