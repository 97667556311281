import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
    UpdateGroupItem,
    UpdateGroups,
    UpdateSelectedGroup,
    UpdateCertainPolicyInSelectedGroup,
    AddPolicyToSelectedGroup,
    RemovePolicyFromSelectedGroup,
    AddMemberToSelectedGroup,
    RemoveMemberFromSelectedGroup
} from '../actions/groups.actions';
import {Injectable} from '@angular/core';
import {insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {MemberGroup} from '../../shared/models/group.model';

const DEFAULT_SELECTED_GROUP_STATE = {id: null,
    name: '',
    directory_name: '',
    members: [],
    members_representation: [],
    access_policies: []};

export class GroupsStateModel {
    groups: MemberGroup[];
    selectedGroup: MemberGroup;
}

@State<GroupsStateModel>({
    name: 'groups',
    defaults: {
        groups: [],
        selectedGroup: DEFAULT_SELECTED_GROUP_STATE
    }
})

@Injectable()
export class GroupsState {

    @Selector()
    static getGroups(state: GroupsStateModel) {
        return state.groups;
    }

    @Selector()
    static getSelectedGroup(state: GroupsStateModel) {
        return state.selectedGroup;
    }

    @Action(UpdateGroups)
    updateGroups({getState, patchState }: StateContext<GroupsStateModel>, { payload }: UpdateGroups) {
        patchState({
            groups: payload
        });
    }

    @Action(UpdateGroupItem)
    updateGroupItem({getState, setState }: StateContext<GroupsStateModel>, { payload }: UpdateGroupItem) {
        setState(
            patch(
                {groups: updateItem(item => item.id === payload.id,  payload )}
            )
        );
    }

    @Action(UpdateSelectedGroup)
    updateSelectedGroup({getState, setState}: StateContext<GroupsStateModel>, {payload}: UpdateSelectedGroup) {
        const state = getState();
        if (payload == null) {
            payload = DEFAULT_SELECTED_GROUP_STATE;
        }
        setState({
            ...state,
            selectedGroup: {...state.selectedGroup, ...payload}
        });
    }

    @Action(UpdateCertainPolicyInSelectedGroup)
    updateCertainPolicyInSelectedGroup({getState, setState}: StateContext<GroupsStateModel>, {payload}: UpdateCertainPolicyInSelectedGroup) {
        setState(
            patch({
              selectedGroup: patch(
                {access_policies: updateItem(item => item.id === payload.id,  patch({...payload}) )}
              )
            })
        );
    }

    @Action(AddPolicyToSelectedGroup)
    addPolicyToSelectedGroup({getState, setState}: StateContext<GroupsStateModel>, {payload}: AddPolicyToSelectedGroup) {
        setState(
            patch({
              selectedGroup: patch(
                {access_policies: insertItem(payload, 0) }
              )
            })
        );
    }

    @Action(RemovePolicyFromSelectedGroup)
    removePolicyFromSelectedGroup({getState, setState}: StateContext<GroupsStateModel>, {payload}: RemovePolicyFromSelectedGroup) {
        setState(
            patch({
              selectedGroup: patch(
                {access_policies: removeItem( payload) }
              )
            })
        );
    }

    @Action(AddMemberToSelectedGroup)
    addMemberToSelectedGroup({getState, setState}: StateContext<GroupsStateModel>, {payload}: AddMemberToSelectedGroup) {
        setState(
            patch({
              selectedGroup: patch({
                members_representation: insertItem(payload),
                members: insertItem(payload.id)
              })
            })
        );
    }

    @Action(RemoveMemberFromSelectedGroup)
    removeMemberFromSelectedGroup({getState, setState}: StateContext<GroupsStateModel>, {payload}: RemoveMemberFromSelectedGroup) {
        setState(
            patch({
              selectedGroup: patch(
                {
                  members_representation: removeItem( item => item.id === payload),
                  members: removeItem( item => item === payload)
                }
              )
            })
        );
    }
}


